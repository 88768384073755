export const globalMethodsMixin = {
  methods: {
    getFormattedTime(value) {
      if (value.day) {
        const time = value.time;
        const day = value.day;
        const month = new Date(value.year, value.month - 1, value.day).toLocaleString("en-US", { month: "long" });
        const year = value.year;
        return `${time} ${day} ${month} ${year}`;
      } else {
        const date = new Date(value * 1000);
        const day = date.getDate();
        const month = date.toLocaleString("en-US", { month: "long" });
        const year = date.getFullYear();
        const time = date.toLocaleTimeString("en-US", { hour12: false });
        return `${time} ${day} ${month} ${year}`;
      }
    },
  },
};
