export default new Map([
  // nets: new Map([
    ['Backbone', {
      net: { value: 'Backbone', name: 'Backbone'},
      chains: [
        { value: 'main', name: 'main' },
        { value: 'zerochain', name: 'zerochain' },
      ],
      url: 'https://1.api.explorer.cellframe.net/expl'
    }],
    // ['subzero', {
    //   net: { value: 'subzero', name: 'subzero'},
    //   chains: [
    //     { value: 'zerochain', name: 'zerochain' },
    //   ],
    //   url: 'https://1.api.explorer.cellframe.net/expl'
    // }],
    // ['mileena', {
    //   net: { value: 'mileena', name: 'mileena'},
    //   chains: [
    //     { value: 'main', name: 'main' },
    //     { value: 'zerochain', name: 'zerochain' },
    //   ],
    //   url: 'https://1.api.explorer.cellframe.net/expl'
    // }],
    ['KelVPN', {
      net: { value: 'KelVPN', name: 'KelVPN'},
      chains: [
        { value: 'main', name: 'main' },
        { value: 'zerochain', name: 'zerochain' },
      ],
      url: 'https://1.api.explorer.cellframe.net/expl'
    }],
    // ['kelvpn-minkowski', {
    //   net: { value: 'kelvpn-minkowski', name: 'kelvpn-minkowski'},
    //   chains: [
    //     { value: 'main', name: 'main' },
    //     { value: 'zerochain', name: 'zerochain' },
    //   ],
    //   url: 'https://1.api.explorer.cellframe.net/expl'
    // }],
    ['raiden', {
      net: { value: 'raiden', name: 'raiden'},
      chains: [
        { value: 'main', name: 'main' },
        { value: 'zerochain', name: 'zerochain' },
      ],

      url: 'https://1.api.explorer.cellframe.net/expl'
    }],
    ['riemann', {
      net: { value: 'riemann', name: 'riemann'},
      chains: [
        { value: 'main', name: 'main' },
        { value: 'zerochain', name: 'zerochain' },
      ],
      url: 'https://1.api.explorer.cellframe.net/expl'
    }],

  // ]),
])

// export default [
//   {
//     Backbone: {
//       net: { value: 'Backbone', name: 'Backbone'},
//       chains: [
//         { value: 'main', name: 'main' },
//         { value: 'zerochain', name: 'zerochain' },
//       ],
//       url: 'https://engine-scorpion.cellframe.net/expl'
//     }
//   },
//   {
//     subzero: {
//       net: { value: 'subzero', name: 'subzero'},
//       chains: [
//         { value: 'support', name: 'support' },
//         { value: 'zerochain', name: 'zerochain' },
//       ],
//       url: 'https://exp-engine.cellframe.net/expl'
//     }
//   },
//   {
//     mileena: {
//       net: { value: 'mileena', name: 'mileena'},
//       chains: [
//         { value: 'zerochain', name: 'zerochain' },
//         { value: 'main', name: 'main' },
//       ],
//       url: 'https://engine-mileena.cellframe.net/expl'
//     }
//   }
// ]
