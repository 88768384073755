<template>
  <Nav />
  <div class="container">
    <router-view :key="$route.path" />
  </div>
</template>

<script>
import Nav from './components/Nav.vue';

export default {
  name: 'App',
  components: {
    Nav
  },
  watch: {
    '$route.query': {
      handler(value) {
        Object.entries(value).forEach(([key, val]) => {
          localStorage.setItem(key, val);
        })
      },
      deep: true,
    }
  }
}

</script>

<style>
@import "./assets/css/footer.css";

*, *:after, *:before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -o-box-sizing: border-box; box-sizing: border-box; }

html {
  height: 100%;
}

body {
  font-family: 'Blinker', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  height: 100%;
  margin: 0;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;

  --color-primary: #171318;
  /* --color-secondary: #220E34; */
  --color-text-primary: #fff;
  /* --color-text-secondary: #ABABAB; */
  --color-text-secondary: #C1BCC4;
  --color-bg-primary: #262127;
  --color-bg-secondary-light: #676E5D;
  --color-bg-secondary-dark: #171418;
  --color-shadow: 2px 2px 15px #1D1921E6;
  /* --color-accent-primary: #ACDE5C; */
  --color-accent-primary: #BAFF57;
	--color-accent-secondary: #6BB500;
  --color-allert: #FF6E57;

  --transition-primary: all cubic-bezier(.17, .67, .5, .71) .1s;
}

.content {
  position: relative;
  min-height: 100%;
  color: var(--color-text-primary);
  background-color: var(--color-primary);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  width: 100%;
}

.text-overflow {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.w100 {
  width: 100%;
}

.flex {
  display: flex;
}

.flex--col {
  flex-direction: column;
}

.flex--center {
  align-items: center;
}

.flex-jc-c {
  justify-content: center;
}

.flex-jc-sb {
  justify-content: space-between;
}

.flex-jc-end {
  justify-content: flex-end;
}

.transition-primary {
  -webkit-transition: var(--transition-primary);
  -moz-transition: var(--transition-primary);
  -ms-transition: var(--transition-primary);
  -o-transition: var(--transition-primary);
  transition: var(--transition-primary);
}


@media only screen and (max-width: 1023px) {
  body {
    font-size: 16px;
  }

  /* .spacer {
    height: 68px;
  } */
  .content {
    background-image: url(/img/bg/bg_desktop.svg);
  }

  @media only screen and (max-width: 767.7px) {
    h1 {
      font-size: 40px;
    }

    h2 {
      font-size: 30px;
    }

    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 20px;
    }

    .content {
      background-image: url(/img/bg/bg_mobile.svg);
    }
  }
  @media only screen and (min-width: 768px) {
    h1 {
      font-size: 42px;
    }

    h2 {
      font-size: 34px;
    }

    h3 {
      font-size: 28px;
    }

    h4 {
      font-size: 22px;
    }
  }
}


@media only screen and (min-width: 1024px) {
  body {
    font-size: 18px;
  }

  /* .spacer {
    height: 59px;
  } */
  .content {
    background-image: url(/img/bg/bg_desktop.svg);
  }

  @media only screen and (max-width: 1279px) {
    h1 {
      font-size: 42px;
    }

    h2 {
      font-size: 34px;
    }

    h3 {
      font-size: 28px;
    }

    h4 {
      font-size: 22px;
    }
  }
  @media only screen and (min-width: 1280px) {
    h1 {
      font-size: 45px;
    }

    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 30px;
    }

    h4 {
      font-size: 24px;
    }
  }
}
</style>
