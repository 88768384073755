<template>
  <label class="search">
    <input :value="modelValue" @input="updateInput" class="transition-primary" type="text" placeholder="0x28D8106BF04511CE25F51F08FBACD0F346C08A53EE13CC01F9355CED892B3D4D">
  </label>
</template>

<script>
export default {
  name: 'InputSearch',

  // data() {
  //   return {
  //     placeholder: '',
  //   }
  // },

  props: {
    // placeholder: String,
    modelValue: [String, Number],
  },

  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/input-search.css";
/* .search:active.search:before,
.search:focus.search:before,
.search:hover.search:before {
  transform: translateX(50%);
} */
</style>
