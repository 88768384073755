<template>
  <div class="layout">
    <h2>Transaction explorer</h2>
    <section class="section section--option">
      <Select v-model="selectedTxNet" :options="netTxOptions" storage-key="selectedTxNet"/>
      <span>network history contains
        <b>{{ txListCountAnim }}</b> transactions</span>
    </section>
    <section class="section">
      <!-- dialog -->
      <Dialog v-model:show="dialogTxVisible">
        <ul v-if="
          txDetails.get(this.dialogTxValue) &&
          txDetails.get(this.dialogTxValue).error
        " class="list-info">
          <li>
            <span class="label">Error</span>
            <span class="value">{{
              txDetails.get(this.dialogTxValue).error
            }}</span>
          </li>
        </ul>
        <ul v-else-if="!txDetails.get(this.dialogTxValue)" class="list-info">
          <li>
            <span class="value">Loading...</span>
          </li>
        </ul>
        <ul v-else class="list-info">
          <li class="w100">
            <span class="label">Transaction hash</span>
            <div class="hash__item">
              <router-link :to="{
                name: 'TransactionDetails',
                params: {
                  hash: txDetails.get(this.dialogTxValue).hash,
                  net: this.selectedTxNet,
                  url: this.txUrl,
                },
              }" class="value value--action text-overflow">
                <span>{{ txDetails.get(this.dialogTxValue).hash }}</span>
              </router-link>
              <router-link :to="{
                name: 'TransactionDetails',
                params: {
                  hash: txDetails.get(this.dialogTxValue).hash,
                  net: this.selectedTxNet,
                  url: this.txUrl,
                },
              }" class="value value--action link--arrow"
                 target="_blank">
                <img src="@/assets/img/arrow_external-link.svg" class="transition-primary" title="open in new tab"
                  alt="open in new tab" />
              </router-link>
            </div>
          </li>
          <li v-if="txDetails.get(this.dialogTxValue).token.emissionHash" class="list-bg">
            <ul class="list-info__list">
              <li>
                <span class="label">Timestamp</span>
                  <span class="value">
                    {{ getFormattedTime(txDetails.get(this.dialogTxValue).dateTime) }}
                  </span>
              </li>
              <li>
                <span class="label">Token ticker</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).token.ticker
                }}</span>
              </li>
              <li class="w100">
                <span class="label">Emission chain id</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).token.emissionChainId
                }}</span>
              </li>
              <li class="w100">
                <span class="label">Emission hash</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).token.emissionHash
                }}</span>
              </li>
            </ul>
          </li>
          <li class="list-bg">
            <ul v-for="i in txDetails.get(this.dialogTxValue).in" :key="i.prev_hash" class="list-info__list">
              <li v-if="i.prev_hash || i.prev_hash == 0">
                <span class="label">IN tx prev idx</span>
                <span class="value">{{ i.prev_hash }}</span>
              </li>
              <li v-if="i.prev_hash">
                <span class="label">IN tx prev hash</span>
                <span class="value">{{ i.prev_hash }}</span>
              </li>
            </ul>
          </li>
          <li v-if="txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock" class="list-bg">
            <ul class="list-info__list">
              <li v-if="
                txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                  .value
              ">
                <span class="label">OUT STAKE Value</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                    .value
                }}
                  coins</span>
              </li>
              <li v-if="
                txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                  .unlock
              ">
                <span class="label">Unlock date</span>
                <span class="value">
                  {{
                    txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                      .unlock.time
                  }}
                  {{
                    txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                      .unlock.day
                  }}
                  {{
                    monthNames[
                    txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                      .unlock.month - 1
                    ]
                  }}
                  {{
                    txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                      .unlock.year
                  }}
                </span>
              </li>
              <li v-if="
                txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                  .reivestPercent
              ">
                <span class="label">Reinvestment percentage</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                    .reivestPercent
                }}
                  %
                </span>
              </li>
            </ul>
          </li>
          <li class="list-bg">
            <ul v-for="i in txDetails.get(this.dialogTxValue).out" :key="i.value" class="list-info__list">
              <li v-if="i.value">
                <span class="label">OUT Value</span>
                <span class="value">{{ i.value }} coins</span>
              </li>
              <li v-if="i.addr">
                <span class="label">OUT Address</span>
                <span class="value">{{ i.addr }}</span>
              </li>
            </ul>
          </li>
          <li class="list-bg">
            <ul v-if="txDetails.get(this.dialogTxValue).sig[0]" class="list-info__list">
              <li>
                <span class="label">SIG size</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).sig[0].size
                }}</span>
              </li>
              <li>
                <span class="label">SIGNATURE type</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).sig[0].sign.type
                }}</span>
              </li>
              <li>
                <span class="label">SIGNATURE size</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).sig[0].sign.size
                }}</span>
              </li>
              <li>
                <span class="label">SIGNATURE fingerprint</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).sig[0].sign.pkey_hash
                }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </Dialog>
      <Dialog v-model:show="dialogBalanceVisible" class="check-balance">
        <h2>Balance</h2>
        <div class="modal-body">
          <div v-if="Balance.balance">
            <ul v-if="Balance.balance.length" class="item__body list-info">
              <li class="list-bg" v-for="item in Balance.balance" :key="item.token">
                <ul v-if="item" class="list-info__list">
                  <li v-if="item.token">
                    <span class="label">Token</span>
                    <span class="value">{{ item.token }}</span>
                  </li>
                  <li v-if="item.coins">
                    <span class="label">Coins</span>
                    <span class="value">{{ item.coins }}</span>
                  </li>
                  <li v-if="item.datoshi">
                    <span class="label">Coins (datoshi)</span>
                    <span class="value">{{ item.datoshi }}</span>
                  </li>
                  <li v-if="Balance.net">
                    <span class="label">Net</span>
                    <span class="value">{{ Balance.net }}</span>
                  </li>
                  <li v-if="Balance.addr">
                    <span class="label">Wallet address</span>
                    <span class="value">{{ Balance.addr }}</span>
                  </li>
                </ul>
              </li>
            </ul>
            <ul v-else class="item__body list-info">
              <li class="list-bg">
                <ul class="list-info__list">
                  <li>
                    <span class="label">Coins</span>
                    <span class="value">Empty</span>
                  </li>
                  <li v-if="Balance.net">
                    <span class="label">Net</span>
                    <span class="value">{{ Balance.net }}</span>
                  </li>
                  <li v-if="Balance.addr">
                    <span class="label">Wallet address</span>
                    <span class="value">{{ Balance.addr }}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <ul v-else-if="Balance.error" class="item__body list-info">
            <li class="list-bg">
              <ul class="list-info__list">
                <li>
                  <span class="label">Error</span>
                  <span class="value">{{ Balance.error.msg }}</span>
                </li>
              </ul>
            </li>
          </ul>
          <ul v-else-if="balanceLoading" class="item__body list-info">
            <li class="list-bg">
              <ul class="list-info__list">
                <li>
                  <span class="label">Token</span>
                  <span class="value anim"></span>
                </li>
                <li>
                  <span class="label">Coins</span>
                  <span class="value anim anim-delay-1"></span>
                </li>
                <li>
                  <span class="label">Coins (datoshi)</span>
                  <span class="value anim anim-delay-2"></span>
                </li>
                <li>
                  <span class="label">Net</span>
                  <span class="value anim anim-delay-3"></span>
                </li>
                <li class="w100">
                  <span class="label">Wallet address</span>
                  <span class="value anim"></span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </Dialog>
      <!-- /dialog -->
      <div class="section__meta">
        <label class="search">
          <input v-model="inputTxValue" class="transition-primary" :disabled="txSearchLoading" type="text"
            placeholder="0xAC3A4ADE5AF21FDC18B3FA34271696FA8AC9153F59D8F95F5BC5B452D8DC0D3B" />
          <button @click="searchTxItem()" class="search-button transition-primary" type="button" name="button">
            <svg v-if="!txSearchLoading" xmlns="http://www.w3.org/2000/svg" width="11.819" height="11.819"
              viewBox="0 0 11.819 11.819">
              <path
                d="M13.535,12.528,10.91,9.909a4.991,4.991,0,1,0-1,1l2.619,2.625a.712.712,0,0,0,1.006,0h0a.712.712,0,0,0,0-1.006Zm-6.6-2.042a3.532,3.532,0,1,1,3.532-3.532,3.532,3.532,0,0,1-3.532,3.532Z"
                transform="translate(-1.924 -1.924)" />
            </svg>
            <svg v-else class="search-loader" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
              enable-background="new 0 0 0 0" xml:space="preserve">
              <path fill="#fff"
                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50"
                  to="360 50 50" repeatCount="indefinite" />
              </path>
            </svg>
          </button>
        </label>
        <Select v-model="selectedSortTxItem" :options="sortTxOptions" storage-key="selectedSortTxItem"/>
      </div>
      <table class="table table--transaction">
        <tbody v-if="!txLoading" class="table__list">
          <tr v-for="item in txList" :key="item.hash" class="table__item">
            <details>
              <summary @click="getTxAndBalance(item)" class="item__header">
                <td data-label="Timestamp" class="timestamp">
                  {{ getFormattedTime(item.dateTime) }}
                </td>
                <td v-if="item.token" data-label="Token ticker" class="ticker">
                  {{ item.token.ticker }}
                </td>
                <td v-else data-label="Token ticker" class="ticker">
                  ---------
                </td>
                <td data-label="Hash" class="hash">
                  <div class="hash__wrap">
                    <span class="hash__pseudo">0x0</span>
                    <span class="hash__primary transition-primary">{{
                      item.hash
                    }}</span>
                    <router-link :to="{
                      name: 'TransactionDetails',
                      params: {
                        hash: item.hash,
                        net: this.selectedTxNet,
                        url: this.txUrl,
                      },
                    }" class="value value--action link--arrow"
                       target="_blank">
                      <img src="@/assets/img/arrow_external-link.svg" class="transition-primary" title="open in new tab"
                        alt="open in new tab" />
                    </router-link>
                  </div>
                </td>
              </summary>
              <ul v-if="
                txDetails.get(item.hash) && txDetails.get(item.hash).error
              " :ref="'hash-ref-' + item.hash" class="item__body list-info">
                <li>
                  <span class="label">Error</span>
                  <span class="value">{{
                    txDetails.get(item.hash).error.msg
                  }}</span>
                </li>
              </ul>
              <ul v-else-if="txDetails.get(item.hash)" class="item__body list-info">
                <li v-if="txDetails.get(item.hash).token.emissionHash" class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">Emission chain id</span>
                      <span class="value">{{
                        txDetails.get(item.hash).token.emissionChainId
                      }}</span>
                    </li>
                    <li class="w100">
                      <span class="label">Emission hash</span>
                      <span class="value">{{
                        txDetails.get(item.hash).token.emissionHash
                      }}</span>
                    </li>
                  </ul>
                </li>
                <li v-if="
                  txDetails.get(item.hash).in &&
                  txDetails.get(item.hash).in.length > 1
                " class="list-bg">
                  <ul v-for="i in txDetails.get(item.hash).in.slice(0, 1)" :key="i.prev_hash" class="list-info__list">
                    <li v-if="i.prev_idx || i.prev_idx == 0">
                      <span class="label">IN tx prev idx</span>
                      <span class="value">{{ i.prev_idx }}</span>
                    </li>
                    <li v-if="i.prev_hash" class="w100">
                      <span class="label">IN tx prev hash</span>
                      <span class="value">{{ i.prev_hash }}</span>
                    </li>
                  </ul>
                  <details class="slice-more">
                    <summary class="slice-more__title transition-primary">
                      <span class="transition-primary">+{{ txDetails.get(item.hash).in.length - 1 }}</span>
                    </summary>
                    <ul v-for="i in txDetails.get(item.hash).in.slice(1)" :key="i.value" class="list-info__list">
                      <li v-if="i.prev_idx || i.prev_idx == 0">
                        <span class="label">IN tx prev idx</span>
                        <span class="value">{{ i.prev_idx }}</span>
                      </li>
                      <li v-if="i.prev_hash" class="w100">
                        <span class="label">IN tx prev hash</span>
                        <span class="value">{{ i.prev_hash }}</span>
                      </li>
                    </ul>
                  </details>
                </li>
                <li v-else-if="
                  txDetails.get(item.hash).in &&
                  txDetails.get(item.hash).in.length < 2 &&
                  txDetails.get(item.hash).in.length > 0
                " class="list-bg">
                  <ul v-for="i in txDetails.get(item.hash).in" :key="i.prev_hash" class="list-info__list">
                    <li v-if="i.prev_idx || i.prev_idx == 0">
                      <span class="label">IN tx prev idx</span>
                      <span class="value">{{ i.prev_idx }}</span>
                    </li>
                    <li v-if="i.prev_hash" class="w100">
                      <span class="label">IN tx prev hash</span>
                      <span class="value">{{ i.prev_hash }}</span>
                    </li>
                  </ul>
                </li>
                <li v-if="txDetails.get(item.hash).out_cond_srv_stake_lock" class="list-bg">
                  <ul class="list-info__list">
                    <li v-if="
                      txDetails.get(item.hash).out_cond_srv_stake_lock.value
                    ">
                      <span class="label">OUT STAKE Value</span>
                      <span class="value">{{
                        txDetails.get(item.hash).out_cond_srv_stake_lock.value
                      }}
                        coins</span>
                    </li>
                    <li v-if="
                      txDetails.get(item.hash).out_cond_srv_stake_lock.unlock
                    ">
                      <span class="label">Unlock date</span>
                      <span class="value">
                        {{
                          txDetails.get(item.hash).out_cond_srv_stake_lock
                            .unlock.time
                        }}
                        {{
                          txDetails.get(item.hash).out_cond_srv_stake_lock
                            .unlock.day
                        }}
                        {{
                          monthNames[
                          txDetails.get(item.hash).out_cond_srv_stake_lock
                            .unlock.month - 1
                          ]
                        }}
                        {{
                          txDetails.get(item.hash).out_cond_srv_stake_lock
                            .unlock.year
                        }}
                      </span>
                    </li>
                    <li v-if="
                      txDetails.get(item.hash).out_cond_srv_stake_lock
                        .reivestPercent
                    ">
                      <span class="label">Reinvestment percentage</span>
                      <span class="value">{{
                        txDetails.get(item.hash).out_cond_srv_stake_lock
                          .reivestPercent
                      }}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li v-if="
                  txDetails.get(item.hash).out &&
                  txDetails.get(item.hash).out.length > 1
                " class="list-bg">
                  <ul v-for="i in txDetails.get(item.hash).out.slice(0, 1)" :key="i.value" class="list-info__list">
                    <li v-if="txDetails.get(item.hash).out_cond[0]" class="list-bg">
                      <ul class="list-info__list">
                        <li>
                          <span class="label">Сommission</span>
                          <span class="value">{{
                            txDetails.get(item.hash).out_cond[0].value
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Type</span>
                          <span class="value">{{
                            txDetails.get(item.hash).out_cond[0].subtype
                          }}</span>
                        </li>
                      </ul>
                    </li>
                    <li v-if="i.value">
                      <span class="label">OUT Value</span>
                      <span v-if="item.token.ticker === 'KEL'" class="value">{{ findTokenBalance('KEL') }} coins</span>
                      <span v-else-if="item.token.ticker === 'CELL'" class="value">{{ findTokenBalance('CELL') }} coins</span>
                      <span v-else-if="item.token.ticker === 'tKEL'" class="value">{{ findTokenBalance('tKEL') }} coins</span>
                      <span v-else-if="item.token.ticker === 'tCELL'" class="value">{{ findTokenBalance('tCELL') }} coins</span>
                    </li>
                    <li v-if="i.addr">
                      <span class="label">OUT Address</span>
                      <div v-if="this.selectedTxNet" class="out__wrap">
                        <span class="value">{{ i.addr }}</span>
                        <div @click="
                          balanceShow(
                            this.nets.get(this.selectedTxNet).net,
                            i.addr
                          )
                        " class="value value--action link--wallet">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                            <g id="Слой_2" data-name="Слой 2">
                              <g id="Слой_1-2" data-name="Слой 1">
                                <g>
                                  <path
                                    d="M30.34,0H9.66A9.65,9.65,0,0,0,0,9.66V30.34A9.65,9.65,0,0,0,9.66,40H30.34A9.65,9.65,0,0,0,40,30.34V9.66A9.65,9.65,0,0,0,30.34,0ZM28.27,9.09l.59,3.71H12.25L27.18,8.4A.85.85,0,0,1,28.27,9.09Zm3.07,20.36a2.19,2.19,0,0,1-2.19,2.19H11.92A3.64,3.64,0,0,1,8.28,28V12.53a3.09,3.09,0,0,1,3.09-3.09h8l-5.24,1.64H11.37a1.45,1.45,0,0,0-.2,2.89h18a2.18,2.18,0,0,1,2.19,2.18v2.66h-6a4,4,0,0,0,0,8h6ZM30.19,25.6H25.51a2.8,2.8,0,0,1,0-5.6h4.68a2.8,2.8,0,0,1,0,5.6Z" />
                                  <circle cx="25.56" cy="22.8" r="0.92" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <span v-else class="value">{{ i.addr }}</span>
                    </li>
                  </ul>
                  <details class="slice-more">
                    <summary class="slice-more__title transition-primary">
                      <span class="transition-primary">+{{ txDetails.get(item.hash).out.length - 1 }}</span>
                    </summary>
                    <ul v-for="i in txDetails.get(item.hash).out.slice(1)" :key="i.value" class="list-info__list">
                      <li v-if="i.value">
                        <span class="label">OUT Value</span>
                        <span class="value">{{ i.value }} coins</span>
                      </li>
                      <li v-if="i.addr">
                        <span class="label">OUT Address</span>
                        <div v-if="this.selectedTxNet" class="out__wrap">
                          <span class="value">{{ i.addr }}</span>
                          <div @click="
                            balanceShow(
                              this.nets.get(this.selectedTxNet).net,
                              i.addr
                            )
                          " class="value value--action link--wallet">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                              <g id="Слой_2" data-name="Слой 2">
                                <g id="Слой_1-2" data-name="Слой 1">
                                  <g>
                                    <path
                                      d="M30.34,0H9.66A9.65,9.65,0,0,0,0,9.66V30.34A9.65,9.65,0,0,0,9.66,40H30.34A9.65,9.65,0,0,0,40,30.34V9.66A9.65,9.65,0,0,0,30.34,0ZM28.27,9.09l.59,3.71H12.25L27.18,8.4A.85.85,0,0,1,28.27,9.09Zm3.07,20.36a2.19,2.19,0,0,1-2.19,2.19H11.92A3.64,3.64,0,0,1,8.28,28V12.53a3.09,3.09,0,0,1,3.09-3.09h8l-5.24,1.64H11.37a1.45,1.45,0,0,0-.2,2.89h18a2.18,2.18,0,0,1,2.19,2.18v2.66h-6a4,4,0,0,0,0,8h6ZM30.19,25.6H25.51a2.8,2.8,0,0,1,0-5.6h4.68a2.8,2.8,0,0,1,0,5.6Z" />
                                    <circle cx="25.56" cy="22.8" r="0.92" />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <span v-else class="value">{{ i.addr }}</span>
                      </li>
                    </ul>
                  </details>
                </li>
                <li v-else-if="
                  txDetails.get(item.hash).out &&
                  txDetails.get(item.hash).out.length < 2 &&
                  txDetails.get(item.hash).out.length > 0
                " class="list-bg">
                  <ul v-for="i in txDetails.get(item.hash).out" :key="i.value" class="list-info__list">
                    <li v-if="txDetails.get(item.hash).out_cond[0]" class="list-bg">
                      <ul class="list-info__list">
                        <li>
                          <span class="label">Сommission</span>
                          <span class="value">{{
                            txDetails.get(item.hash).out_cond[0].value
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Type</span>
                          <span class="value">{{
                            txDetails.get(item.hash).out_cond[0].subtype
                          }}</span>
                        </li>
                      </ul>
                    </li>
                    <li v-if="i.value">
                      <span class="label">OUT Value</span>
                      <span v-if="item.token.ticker === 'KEL'" class="value">{{ findTokenBalance('KEL') }} coins</span>
                      <span v-else-if="item.token.ticker === 'CELL'" class="value">{{ findTokenBalance('CELL') }} coins</span>
                      <span v-else-if="item.token.ticker === 'tKEL'" class="value">{{ findTokenBalance('tKEL') }} coins</span>
                      <span v-else-if="item.token.ticker === 'tCELL'" class="value">{{ findTokenBalance('tCELL') }} coins</span>
                    </li>
                    <li v-if="i.addr">
                      <span class="label">OUT Address</span>
                      <div v-if="this.selectedTxNet" class="out__wrap">
                        <span class="value">{{ i.addr }}</span>
                        <div @click="
                          balanceShow(
                            this.nets.get(this.selectedTxNet).net,
                            i.addr
                          )
                        " class="value value--action link--wallet">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                            <g id="Слой_2" data-name="Слой 2">
                              <g id="Слой_1-2" data-name="Слой 1">
                                <g>
                                  <path
                                    d="M30.34,0H9.66A9.65,9.65,0,0,0,0,9.66V30.34A9.65,9.65,0,0,0,9.66,40H30.34A9.65,9.65,0,0,0,40,30.34V9.66A9.65,9.65,0,0,0,30.34,0ZM28.27,9.09l.59,3.71H12.25L27.18,8.4A.85.85,0,0,1,28.27,9.09Zm3.07,20.36a2.19,2.19,0,0,1-2.19,2.19H11.92A3.64,3.64,0,0,1,8.28,28V12.53a3.09,3.09,0,0,1,3.09-3.09h8l-5.24,1.64H11.37a1.45,1.45,0,0,0-.2,2.89h18a2.18,2.18,0,0,1,2.19,2.18v2.66h-6a4,4,0,0,0,0,8h6ZM30.19,25.6H25.51a2.8,2.8,0,0,1,0-5.6h4.68a2.8,2.8,0,0,1,0,5.6Z" />
                                  <circle cx="25.56" cy="22.8" r="0.92" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <span v-else class="value">{{ i.addr }}</span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul v-if="txDetails.get(item.hash).sig[0]" class="list-info__list">
                    <li>
                      <span class="label">SIG size</span>
                      <span class="value">{{
                        txDetails.get(item.hash).sig[0].size
                      }}</span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE type</span>
                      <span class="value">{{
                        txDetails.get(item.hash).sig[0].sign.type
                      }}</span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE size</span>
                      <span class="value">{{
                        txDetails.get(item.hash).sig[0].sign.size
                      }}</span>
                    </li>
                    <li class="w100">
                      <span class="label">SIGNATURE fingerprint</span>
                      <span class="value">{{
                        txDetails.get(item.hash).sig[0].sign.pkey_hash
                      }}</span>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul v-else :ref="'hash-ref-' + item.hash" class="item__body list-info">
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">Emission chain id</span>
                      <span class="value anim"></span>
                    </li>
                    <li class="w100">
                      <span class="label">Emission hash</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">IN tx prev idx</span>
                      <span class="value anim"></span>
                    </li>
                    <li class="w100">
                      <span class="label">IN tx prev hash</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">OUT Value</span>
                      <span class="value anim"></span>
                    </li>
                    <li class="w100">
                      <span class="label">OUT Address</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">SIG size</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE type</span>
                      <span class="value anim anim-delay-1"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE size</span>
                      <span class="value anim anim-delay-2"></span>
                    </li>
                    <li class="w100">
                      <span class="label">SIGNATURE fingerprint</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
              </ul>
            </details>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="item in size" :key="item">
            <td data-label="Timestamp" class="timestamp">
              <span class="anim"></span>
            </td>
            <td data-label="Token ticker" class="ticker">
              <span class="anim anim-delay-1"></span>
            </td>
            <td data-label="Hash" class="hash">
              <span class="anim anim-delay-2"></span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button @click="prevTxPage" :disabled="txPage.cur === 1" class="pagination__prev"></button>
        <span>Showing {{ txPage.startItem }} to
          <span v-if="txPage.endItem >= txListCount">{{ txListCount }}</span><span v-else>{{ txPage.endItem }}</span> of
          {{ txListCount }} entries</span>
        <button @click="nextTxPage" :disabled="txPage.cur >= txPage.total" class="pagination__next"></button>
      </div>
    </section>
  </div>
  <Hint v-model:show="hintVisible">
    <p>{{ hintText }}</p>
  </Hint>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import axios from "axios";
import netsAvalible from "@/store/nets.js";

import { globalMethodsMixin } from "@/mixins/globalMethodsMixin";

export default {
  mixins: [globalMethodsMixin],
  data() {
    return {
      // tx
      txSearchLoading: false,
      txLoading: false,

      txList: [],

      txListCount: 0,
      txListCountAnim: 0,

      dialogTxVisible: false,
      dialogTxValue: "",

      inputTxValue: "",

      selectedTxNet: "Backbone",
      netTxOptions: [],

      txDetails: new Map(),

      txPage: {
        total: 0,
        cur: 1,
        pageLimit: 10,
        startItem: 1,
        endItem: 1,
        itemCount: 100,
      },

      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      selectedSortTxItem: "new",
      sortTxOptions: [
        { value: "new", name: "first new" },
        { value: "old", name: "old ones first" },
      ],

      // base url
      txUrl: "https://1.api.explorer.cellframe.net/expl",

      // hint
      hintVisible: false,
      hintText: "Error",

      // balance
      dialogBalanceVisible: false,
      balanceLoading: false,
      selectedBalanceNet: "Backbone",
      netBalanceOptions: [],
      inputBalanceValue: "",
      Balance: [],
    };
  },

  props: {
    size: {
      type: Number,
      required: false,
      default: 100,
    },
  },


  created() {

    if (this.$route.query.selectedTxNet) {
      this.selectedTxNet = this.$route.query.selectedTxNet;
      }
    },

  methods: {
    txAnimateCount(animationDuration) {
      const frameDuration = 1000 / 60;
      const totalFrames = Math.round(animationDuration / frameDuration);
      const easeOutQuad = (t) => 1 - Math.pow(1 - t, 5);

      let frame = 0;
      const countTo = this.txListCount;

      const counter = setInterval(() => {
        frame++;

        const progress = easeOutQuad(frame / totalFrames);

        const currentCount = Math.round(countTo * progress);

        if (this.txListCountAnim !== currentCount) {
          this.txListCountAnim = currentCount;
        }

        if (frame === totalFrames) {
          clearInterval(counter);
        }
      }, frameDuration);
    },

    async txItemsCount() {
      try {
        this.txLoading = true;

        const response = await axios.get(this.txUrl, {
          params: {
            method: "txListCount",
            net: this.selectedTxNet,
          },
        });

        this.txListCount = response.data.countTx;

        if (response.data.countTx <= this.size) {
          this.txPage.itemCount = response.data.countTx;
        } else {
          this.txPage.itemCount = this.size;
        }

        this.txAnimateCount(2000);

        console.log(response.data);

        this.txItemsLimited();

        this.txPageCount();
        this.txPaginatedData();
      } catch (error) {
        this.txLoading = true;
        console.log(error.message);
        this.hintText = error.message;
        this.showHint();
      }
    },

    async txItemsLimited() {
      try {
        let params = {
          method: "txListLimited",
          net: this.selectedTxNet,
          count: this.txPage.itemCount,
          page: this.txPage.cur,
        };
        if (this.selectedSortTxItem == "old") params.reverse = "old";

        setTimeout(async () => {
          const response = await axios.get(this.txUrl, {
            params: params,
          });
          console.log(this.selectedTxNet)

          if (this.selectedTxNet === "kelvpn-minkowski") {
            console.log(response.data.transactions.transactions);
            this.txList = response.data.transactions.transactions;
          } else {
            console.log(response.data.transactions);
            this.txList = response.data.transactions;
          }
          
          this.txLoading = false;
        }, 1000);
      } catch (error) {
        this.txLoading = true;
        console.log(error.message);
        this.hintText = error.message;
        this.showHint();
      }
    },

    async getTxInfo(hash) {
      if (!this.txDetails.has(hash)) {
        try {
          await new Promise(resolve => {
            setTimeout(async () => {
              const response = await axios.get(this.txUrl, {
              params: {
                method: "tx",
                net: this.selectedTxNet,
                // chain: this.selectedChain,
                // hash: hash.replace(/\s+/g, ' ').replace('%00', ' ').trim()
                hash: hash,
              },
            });

            console.log(response.data);
            this.txDetails.set(hash, response.data);
            resolve(); 
            }, 1000);
          });
        } catch (error) {
          console.log(error.message);
          this.hintText = error.message;
          this.showHint();
        }
      }
    },

    isValidHash(hash) {
      console.log(hash)
      const hashRegex = /^0x([A-Fa-f0-9]{64})$/; 
      return hashRegex.test(hash); 
    },
    async searchTxItem() {
      if (this.txSearchLoading) {
        return;
      }
      if (!this.isValidHash(this.inputTxValue)) {
        this.hintText = 'Invalid hash format';
        this.showHint();
        return;
      }
      if (this.txDetails.has(this.inputTxValue)) {
        this.dialogTxValue = this.inputTxValue;
        this.showTxDialog();
      } else {
        this.txSearchLoading = true;
        try {
          const response = await axios.get(this.txUrl, {
            params: {
              method: "tx",
              net: this.selectedTxNet,
              hash: this.inputTxValue,
            },
          });
          // let needle_index = this.$refs['hash-ref-'+hash];

          // this.dagDetails.set(hash, response.data);

          console.log(response.data);
          if (!response.data) {
            throw new Error('Response data is empty');
          }

          if (response.data.error) {
            throw new Error(response.data.error.msg ?? response.data.error);
          }
          if (response.data) {
            this.txDetails.set(this.inputTxValue, response.data);
            this.dialogTxValue = this.inputTxValue;
            this.showTxDialog();
          }
          this.txSearchLoading = false;
        } catch (error) {
          this.txSearchLoading = false;
          console.log(error.message);
          this.hintText = error.message;
          this.showHint();
        }
        // this.searchTxItem(this.inputTxValue, this.selectedTxNet, this.txUrl)
      }
    },

    balanceShow(net, addr) {
      this.dialogBalanceVisible = !this.dialogBalanceVisible;
      this.checkBalance(net, addr);
    },

    balanceShowHome(net, addr) {
      this.checkBalance(net, addr);
    },

    async checkBalance(net, addr) {
      this.Balance = [];
      this.balanceLoading = true;

      try {
        setTimeout(async () => {
          const response = await axios.get(this.txUrl, {
            params: {
              method: "w_addr",
              net: net.name,
              addr: addr,
              fsn: 1,
            },
          });

          console.log(response.data);
          if (response.data.balance) {
            this.Balance = response.data;
          } else {
            this.Balance = response.data;
          }

          this.balanceLoading = false;
        }, 1000);
      } catch (error) {
        console.log(error.message);
        this.balanceLoading = false;
        this.hintText = error.message;
        this.dialogBalanceVisible = !this.dialogBalanceVisible;
        this.showHint();
      }
    },

    showTxDialog() {
      this.dialogTxVisible = true;
    },

    nextTxPage() {
      this.txPage.cur++;
      if (this.selectedSortTxItem == "new") {
        this.txItemsCount();
        this.txPaginatedData();
      } else if (this.selectedSortTxItem == "old") {
        this.txItemsCount();
        this.txPaginatedData();
      }
    },
    prevTxPage() {
      this.txPage.cur--;
      if (this.selectedSortTxItem == "new") {
        this.txItemsCount();
        this.txPaginatedData();
      } else if (this.selectedSortTxItem == "old") {
        this.txItemsCount();
        this.txPaginatedData();
      }
    },
    txPageCount() {
      let l = this.txListCount,
        s = this.txPage.itemCount;
      this.txPage.total = Math.ceil(l / s);
      return this.txPage.total;
    },
    txPaginatedData() {
      const start = Number((this.txPage.cur - 1) * this.txPage.itemCount),
        end = Number(start + this.txPage.itemCount);
      this.txPage.startItem = start + 1;
      this.txPage.endItem = end;
    },

    showHint() {
      // this.hintMsg = msg;
      this.hintVisible = true;

      setTimeout(() => {
        this.hintVisible = false;
      }, 2000);
    },

    async getTxAndBalance(item){
      await this.getTxInfo(item.hash);
      const net = this.nets.get(this.selectedTxNet).net;
      if (this.txDetails.get(item.hash)) {
        const addr = this.txDetails.get(item.hash).out[0].addr;
        this.balanceShowHome(net, addr)
      } else {
        console.error("Transaction details not found for hash:", item.hash);
      }  
    },

    findTokenBalance(tokenTicker) {
      if (this.Balance.balance) {
        const tokenBalance = this.Balance.balance.find(token => token.token === tokenTicker);
        return tokenBalance ? tokenBalance.coins : 'N/A'; 
      }
    }

  },

  mounted() {
    for (let entry of this.nets) {
      this.netTxOptions.push(entry[1].net);
    }
    this.netBalanceOptions = this.netTxOptions;
    this.txItemsCount();
  },

  // computed: {
  //   ...mapGetters([
  //     "activeNetwork",
  //   ]),
  // },

  setup() {
    const nets = ref(netsAvalible);
    return { nets };
  },

  watch: {
    selectedTxNet() {      
      this.txUrl = this.nets.get(this.selectedTxNet).url;
      this.txPage.cur = 1;
      this.selectedSortTxItem = "new";
      this.txItemsCount();

      this.$router.push({
        name: 'Transaction',
        query: {
          selectedTxNet: this.selectedTxNet,
        }
      });
    },
    // inputTxValue() {
    //   if (this.txDetails.has(this.inputTxValue)) {
    //     this.dialogTxValue = this.inputTxValue
    //     this.showTxDialog()
    //   } else if (this.inputTxValue.length > 60) {
    //     this.searchTxItem(this.inputTxValue, this.selectedTxNet, this.txUrl)
    //   }
    // },
    selectedSortTxItem() {
      this.txPage.cur = 1;
      if (this.selectedSortTxItem == "new") {
        this.txItemsCount();
      } else if (this.selectedSortTxItem == "old") {
        this.txItemsCount();
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/layout.css";
@import "../assets/css/pagination.css";

.layout {
  margin: 48px 0 0;
  padding: 0 16px 48px;
}

.hash__wrap .hash__primary,
.hash__wrap .hash__pseudo {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hash__wrap .hash__pseudo {
  color: var(--color-bg-primary);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.table--transaction .hash__wrap .value {
  margin: 0 0 0 auto;
}

@media screen and (max-width: 767.7px) {
  .hash__wrap .hash__pseudo {
    position: relative;
  }

  .hash__wrap .hash__primary {
    position: absolute;
  }

  .table--transaction .hash__wrap span {
    width: 88%;
  }
}

@media screen and (min-width: 768px) {

  .hash__wrap .hash__primary,
  .hash__wrap .hash__pseudo {
    position: absolute;
  }

  .table--transaction .hash__wrap span {
    width: 90%;
  }
  .timestamp {
    min-width: 210px; 
  }
}
</style>
