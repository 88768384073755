<template>
  <div class="layout">
    <h2>Transaction details</h2>
    <section class="section">
      <Dialog v-model:show="dialogBalanceVisible" class="check-balance">
        <h2>Balance</h2>
        <div class="modal-body">
          <div v-if="Balance.balance">
            <ul v-if="Balance.balance.length" class="item__body list-info">
              <li class="list-bg" v-for="item in Balance.balance" :key="item.token">
                <ul v-if="item" class="list-info__list">
                  <li v-if="item.token">
                    <span class="label">Token</span>
                    <span class="value">{{ item.token }}</span>
                  </li>
                  <li v-if="item.coins">
                    <span class="label">Coins</span>
                    <span class="value">{{ item.coins }}</span>
                  </li>
                  <li v-if="item.datoshi">
                    <span class="label">Coins (datoshi)</span>
                    <span class="value">{{ item.datoshi }}</span>
                  </li>
                  <li v-if="Balance.net">
                    <span class="label">Net</span>
                    <span class="value">{{ Balance.net }}</span>
                  </li>
                  <li v-if="Balance.addr">
                    <span class="label">Wallet address</span>
                    <span class="value">{{ Balance.addr }}</span>
                  </li>
                </ul>
              </li>
            </ul>
            <ul v-else class="item__body list-info">
              <li class="list-bg">
                <ul class="list-info__list">
                  <li>
                    <span class="label">Coins</span>
                    <span class="value">Empty</span>
                  </li>
                  <li v-if="Balance.net">
                    <span class="label">Net</span>
                    <span class="value">{{ Balance.net }}</span>
                  </li>
                  <li v-if="Balance.addr">
                    <span class="label">Wallet address</span>
                    <span class="value">{{ Balance.addr }}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <ul v-else-if="Balance.error" class="item__body list-info">
            <li class="list-bg">
              <ul class="list-info__list">
                <li>
                  <span class="label">Error</span>
                  <span class="value">{{ Balance.error.msg }}</span>
                </li>
              </ul>
            </li>
          </ul>
          <ul v-else-if="balanceLoading" class="item__body list-info">
            <li class="list-bg">
              <ul class="list-info__list">
                <li>
                  <span class="label">Token</span>
                  <span class="value anim"></span>
                </li>
                <li>
                  <span class="label">Coins</span>
                  <span class="value anim anim-delay-1"></span>
                </li>
                <li>
                  <span class="label">Coins (datoshi)</span>
                  <span class="value anim anim-delay-2"></span>
                </li>
                <li>
                  <span class="label">Net</span>
                  <span class="value anim anim-delay-3"></span>
                </li>
                <li class="w100">
                  <span class="label">Wallet address</span>
                  <span class="value anim"></span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </Dialog>
      <table class="table">
        <tbody v-if="!txLoading">
          <tr>
            <td data-label="Transaction Hash" class="hash">
              <div class="hash__item">
                <input
                  v-on:focus="$event.target.select()"
                  ref="clone"
                  readonly
                  :value="this.$route.params.hash"
                />
                <button @click="copy" ref="btnToggle" class="copy-btn">
                  <span>copy</span>
                </button>
              </div>
              <ul v-if="txItem && txItem.error" class="list-info">
                <li>
                  <span class="label">Error</span>
                  <span class="value">{{ txItem.error.msg }}</span>
                </li>
              </ul>
              <ul v-else-if="!txItem" class="list-info">
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">Timestamp</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">Token ticker</span>
                      <span class="value anim anim-delay-1"></span>
                    </li>
                    <li class="w100">
                      <span class="label">Emission chain id</span>
                      <span class="value anim"></span>
                    </li>
                    <li class="w100">
                      <span class="label">Emission hash</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">IN tx prev idx</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">IN tx prev hash</span>
                      <span class="value anim anim-delay-1"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">OUT Value</span>
                      <span class="value anim"></span>
                    </li>
                    <li class="w100">
                      <span class="label">OUT Address</span>
                      <div class="out__wrap">
                        <span class="value anim"></span>
                        <div @click="
                          balanceShow(
                            this.nets.get(this.selectedTxNet).net,
                            i.addr
                          )
                        " class="value value--action link--wallet">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                            <g id="Слой_2" data-name="Слой 2">
                              <g id="Слой_1-2" data-name="Слой 1">
                                <g>
                                  <path
                                    d="M30.34,0H9.66A9.65,9.65,0,0,0,0,9.66V30.34A9.65,9.65,0,0,0,9.66,40H30.34A9.65,9.65,0,0,0,40,30.34V9.66A9.65,9.65,0,0,0,30.34,0ZM28.27,9.09l.59,3.71H12.25L27.18,8.4A.85.85,0,0,1,28.27,9.09Zm3.07,20.36a2.19,2.19,0,0,1-2.19,2.19H11.92A3.64,3.64,0,0,1,8.28,28V12.53a3.09,3.09,0,0,1,3.09-3.09h8l-5.24,1.64H11.37a1.45,1.45,0,0,0-.2,2.89h18a2.18,2.18,0,0,1,2.19,2.18v2.66h-6a4,4,0,0,0,0,8h6ZM30.19,25.6H25.51a2.8,2.8,0,0,1,0-5.6h4.68a2.8,2.8,0,0,1,0,5.6Z" />
                                  <circle cx="25.56" cy="22.8" r="0.92" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">SIG size</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE type</span>
                      <span class="value anim anim-delay-1"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE size</span>
                      <span class="value anim anim-delay-2"></span>
                    </li>
                    <li class="w100">
                      <span class="label">SIGNATURE fingerprint</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul v-else class="list-info">
                <li v-if="txItem.token" class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">Timestamp</span>
                      <span class="value">
                        {{ getFormattedTime(txItem.dateTime) }}
                      </span>
                    </li>
                    <li>
                      <span class="label">Token ticker</span>
                      <span class="value">{{ txItem.token.ticker }}</span>
                    </li>
                    <li v-if="txItem.token.emissionChainId" class="w100">
                      <span class="label">Emission chain id</span>
                      <span class="value">{{
                        txItem.token.emissionChainId
                      }}</span>
                    </li>
                    <li v-if="txItem.token.emissionHash" class="w100">
                      <span class="label">Emission hash</span>
                      <span class="value">{{ txItem.token.emissionHash }}</span>
                    </li>
                  </ul>
                </li>
                <li v-if="txItem.in && txItem.in.length > 0" class="list-bg">
                  <ul
                    v-for="i in txItem.in"
                    :key="i.prev_hash"
                    class="list-info__list"
                  >
                    <li v-if="i.prev_idx || i.prev_idx == 0">
                      <span class="label">IN tx prev idx</span>
                      <span class="value">{{ i.prev_idx }}</span>
                    </li>
                    <li v-if="i.prev_hash">
                      <span class="label">IN tx prev hash</span>
                      <span class="value">{{ i.prev_hash }}</span>
                    </li>
                  </ul>
                </li>
                <li v-if="txItem.out_cond_srv_stake_lock[0]" class="list-bg">
                  <ul class="list-info__list">
                    <li v-if="txItem.out_cond_srv_stake_lock[0].value">
                      <span class="label">OUT STAKE Value</span>
                      <span class="value"
                        >{{ txItem.out_cond_srv_stake_lock[0].value }} coins</span
                      >
                    </li>
                    <li v-if="txItem.out_cond_srv_stake_lock[0].unlock">
                      <span class="label">Unlock date</span>
                      <span class="value">
                        {{ txItem.out_cond_srv_stake_lock[0].unlock.time }}
                        {{ txItem.out_cond_srv_stake_lock[0].unlock.day }}
                        {{
                          monthNames[
                            txItem.out_cond_srv_stake_lock[0].unlock.month - 1
                          ]
                        }}
                        {{ txItem.out_cond_srv_stake_lock[0].unlock.year }}
                      </span>
                    </li>
                    <li v-if="txItem.out_cond_srv_stake_lock[0].reivestPercent">
                      <span class="label">Reinvestment percentage</span>
                      <span class="value"
                        >{{ txItem.out_cond_srv_stake_lock[0].reivestPercent }}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li v-if="txItem.out && txItem.out.length > 0" class="list-bg">
                  <ul
                    v-for="i in txItem.out"
                    :key="i.value"
                    class="list-info__list"
                  >
                    <li v-if="i.value">
                      <span class="label">OUT Value</span>
                      <span class="value">{{ i.value }} coins</span>
                    </li>
                    <li v-if="i.addr">
                      <span class="label">OUT Address</span>
                      <div class="out__wrap">
                        <span class="value">{{ i.addr }}</span>
                        <div @click="
                          balanceShow(
                            this.nets.get(this.selectedTxNet).net,
                            i.addr
                          )
                        " class="value value--action link--wallet">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                            <g id="Слой_2" data-name="Слой 2">
                              <g id="Слой_1-2" data-name="Слой 1">
                                <g>
                                  <path
                                    d="M30.34,0H9.66A9.65,9.65,0,0,0,0,9.66V30.34A9.65,9.65,0,0,0,9.66,40H30.34A9.65,9.65,0,0,0,40,30.34V9.66A9.65,9.65,0,0,0,30.34,0ZM28.27,9.09l.59,3.71H12.25L27.18,8.4A.85.85,0,0,1,28.27,9.09Zm3.07,20.36a2.19,2.19,0,0,1-2.19,2.19H11.92A3.64,3.64,0,0,1,8.28,28V12.53a3.09,3.09,0,0,1,3.09-3.09h8l-5.24,1.64H11.37a1.45,1.45,0,0,0-.2,2.89h18a2.18,2.18,0,0,1,2.19,2.18v2.66h-6a4,4,0,0,0,0,8h6ZM30.19,25.6H25.51a2.8,2.8,0,0,1,0-5.6h4.68a2.8,2.8,0,0,1,0,5.6Z" />
                                  <circle cx="25.56" cy="22.8" r="0.92" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li v-if="txItem.sig[0]" class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">SIG size</span>
                      <span class="value">{{ txItem.sig[0].size }}</span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE type</span>
                      <span class="value">{{ txItem.sig[0].sign.type }}</span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE size</span>
                      <span class="value">{{ txItem.sig[0].sign.size }}</span>
                    </li>
                    <li class="w100">
                      <span class="label">SIGNATURE fingerprint</span>
                      <span class="value">{{ txItem.sig[0].sign.pkey_hash }}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td data-label="Transaction Hash" class="hash">
              <div class="hash__item">
                <span class="anim"></span>
              </div>
              <ul class="list-info">
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">Emission chain id</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">Emission hash</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">Token ticker</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">IN tx prev idx</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">IN tx prev hash</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">OUT Value</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">OUT Address</span>
                      <div class="out__wrap">
                        <span class="value anim anim-delay-1"></span>
                        <div @click="
                          balanceShow(
                            this.nets.get(this.selectedTxNet).net,
                            i.addr
                          )
                        " class="value value--action link--wallet">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                            <g id="Слой_2" data-name="Слой 2">
                              <g id="Слой_1-2" data-name="Слой 1">
                                <g>
                                  <path
                                    d="M30.34,0H9.66A9.65,9.65,0,0,0,0,9.66V30.34A9.65,9.65,0,0,0,9.66,40H30.34A9.65,9.65,0,0,0,40,30.34V9.66A9.65,9.65,0,0,0,30.34,0ZM28.27,9.09l.59,3.71H12.25L27.18,8.4A.85.85,0,0,1,28.27,9.09Zm3.07,20.36a2.19,2.19,0,0,1-2.19,2.19H11.92A3.64,3.64,0,0,1,8.28,28V12.53a3.09,3.09,0,0,1,3.09-3.09h8l-5.24,1.64H11.37a1.45,1.45,0,0,0-.2,2.89h18a2.18,2.18,0,0,1,2.19,2.18v2.66h-6a4,4,0,0,0,0,8h6ZM30.19,25.6H25.51a2.8,2.8,0,0,1,0-5.6h4.68a2.8,2.8,0,0,1,0,5.6Z" />
                                  <circle cx="25.56" cy="22.8" r="0.92" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">SIG size</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE type</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE size</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE fingerprint</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
  <Hint v-model:show="hintVisible">
    <p>{{ hintText }}</p>
  </Hint>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import axios from "axios";
import netsAvalible from "@/store/nets.js";
import { globalMethodsMixin } from "@/mixins/globalMethodsMixin";

export default {
  mixins: [globalMethodsMixin],
  data() {
    return {
      hintVisible: false,
      hintText: "Error",

      txItem: "",

      inputTxValue: "",

      selectedTxNet: "",

      txLoading: false,

      text: "This will get copied!",

      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      txUrl: "",
      unifiedEndpoint: 'https://1.api.explorer.cellframe.net/expl',

      // balance
      dialogBalanceVisible: false,
      balanceLoading: false,
      selectedBalanceNet: "Backbone",
      netBalanceOptions: [],
      inputBalanceValue: "",
      Balance: [],
    };
  },
  props: {
    size: {
      type: Number,
      required: false,
      default: 100,
    },
  },

  setup() {
    const nets = ref(netsAvalible);
    return { nets };
  },

  methods: {
    async searchTxItem(hash) {
      try {
        setTimeout(async () => {
          const response = await axios.get(this.txUrl, {
            params: {
              method: "tx",
              net: this.selectedTxNet,
              hash: hash,
            },
          });
          // let needle_index = this.$refs['hash-ref-'+hash];

          // this.dagDetails.set(hash, response.data);

          console.log(response.data);
          this.txItem = response.data;
          // if (response.data) {
          //   this.txDetails.set(hash, response.data);
          //   this.dialogTxValue = hash
          //   this.showTxDialog()
          // }
        }, 1000);
      } catch (error) {
        console.log(error.message);
        this.hintText = error.message;
        this.showHint();
      }
    },

    copy() {
      // console.log(this.$route.fullPath)
      // var Url = this.$route.fullPath;
      // Url.innerHTML = window.location.href;
      // Url.select();
      // console.log(Url.innerHTML)
      this.$refs.btnToggle.innerText = "copy";
      this.$refs.clone.focus();
      document.execCommand("copy");
      this.$refs.btnToggle.innerText = "done";
      setTimeout(async () => {
        this.$refs.btnToggle.innerText = "copy";
      }, 800);
    },

    balanceShow(net, addr) {
      this.dialogBalanceVisible = !this.dialogBalanceVisible;
      this.checkBalance(net, addr);
    },

    async checkBalance(net, addr) {
      this.Balance = [];
      this.balanceLoading = true;

      try {
        setTimeout(async () => {
          const response = await axios.get(this.txUrl, {
            params: {
              method: "w_addr",
              net: net.name,
              addr: addr,
              fsn: 1,
            },
          });

          console.log(response.data);
          if (response.data.balance) {
            this.Balance = response.data;
          } else {
            this.Balance = response.data;
          }

          this.balanceLoading = false;
        }, 1000);
      } catch (error) {
        console.log(error.message);
        this.balanceLoading = false;
        this.hintText = error.message;
        this.dialogBalanceVisible = !this.dialogBalanceVisible;
        this.showHint();
      }
    },
  },

  mounted() {
    this.selectedTxNet = this.$route.params.net;

    if (this.$route.params.url) {
      this.txUrl = this.$route.params.url;
    } else {
     this.txUrl = `${this.unifiedEndpoint}?net=${this.selectedTxNet}`;
    }

this.searchTxItem(this.$route.params.hash);
  },

  watch: {},
};
</script>

<style scoped>
@import "../assets/css/layout.css";
@import "../assets/css/pagination.css";

.layout {
  margin: 48px 0 0;
  padding: 0 16px 48px;
}
.layout h2 {
  text-align: center;
}

.section {
  max-width: 1080px;
}
.section--option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.hash__item {
  position: relative;
}

td input {
  outline: none;
  font-family: inherit;
  font-size: 100%;
  width: 80%;
  margin: 0;
  padding: 0;
  border: none;
  color: var(--color-text-primary);
  background: none;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
td button {
  font-family: inherit;
  /* font-size: 12px; */
  position: absolute;
  right: 0;
  width: 38px;
  margin: 0;
  padding: 4px;
  border: none;
  border-radius: 4px;
  color: var(--color-text-primary);
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
td button:hover {
  color: var(--color-accent-primary);
  background-color: var(--color-bg-secondary-light);
}
</style>
