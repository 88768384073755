<template>
  <label class="select" for="slct">
    <select
      :value="modelValue"
      @change="changeOption"
      id="slct"
      class="transition-primary"
      required="required"
    >
      <option value="" disabled="disabled" selected="selected">Select option</option>
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.name }}
      </option>
    </select>
    <svg>
      <use xlink:href="#select-arrow-down"></use>
    </svg>
  </label>
  <!-- SVG Sprites-->
  <svg class="sprites">
    <symbol id="select-arrow-down" viewbox="0 0 10 6">
      <polyline points="1 1 5 5 9 1"></polyline>
    </symbol>
  </svg>
</template>

<script>
export default {
  name: "Select",

  props: {
    modelValue: [String],
    options: {
      type: Array,
      default: () => [],
    },
    storageKey: String,
  },

  mounted() {
    const savedValue = localStorage.getItem(this.storageKey); 
    if (savedValue) {
      this.$emit("update:modelValue", savedValue);
    }
  },

  methods: {
    changeOption(event) {
      const selectedValue = event.target.value;
      localStorage.setItem(this.storageKey, selectedValue); 
      this.$emit("update:modelValue", selectedValue);
    },
  },
};
</script>

<style scoped>
.select {
  position: relative;
  width: fit-content;
}
.select svg {
  position: absolute;
  right: 12px;
  top: calc(50% - 3px);
  width: 10px;
  height: 6px;
  transform: rotate(90deg);
  transition: transform ease-in-out 0.1s;
  stroke-width: 2px;
  stroke: var(--color-text-primary);
  fill: none;
  /* stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: none; */

  -webkit-transition: transform ease-in-out 0.1s;
  -moz-transition: transform ease-in-out 0.1s;
  -ms-transition: transform ease-in-out 0.1s;
  -o-transition: transform ease-in-out 0.1s;
  transition: transform ease-in-out 0.1s;
}
.select select {
  -webkit-appearance: none;
  outline: none;
  font-size: 16px;
  margin: 0;
  padding: 12px 40px 12px 16px;
  color: var(--color-text-primary);
  background: var(--color-bg-primary);
  border: none;
  border-radius: 16px;
  box-shadow: var(--color-shadow);
  cursor: pointer;
  -webkit-background: var(--color-bg-primary);
  -moz-background: var(--color-bg-primary);
  -ms-background: var(--color-bg-primary);
  -o-background: var(--color-bg-primary);
}
.select:hover select {
  color: var(--color-accent-primary);
  background: var(--color-bg-secondary-light);
  -webkit-background: var(--color-bg-secondary-light);
  -moz-background: var(--color-bg-secondary-light);
  -ms-background: var(--color-bg-secondary-light);
  -o-background: var(--color-bg-secondary-light);
}
.select:hover svg {
  stroke: var(--color-accent-primary);
}
.select select:focus {
  color: var(--color-accent-primary);
  background: var(--color-bg-secondary-light);
  -webkit-background: var(--color-bg-secondary-light);
  -moz-background: var(--color-bg-secondary-light);
  -ms-background: var(--color-bg-secondary-light);
  -o-background: var(--color-bg-secondary-light);
}
.select select:focus + svg {
  stroke: var(--color-accent-primary);
}
.select select:required:invalid {
  color: #5a667f;
}
.select select option[value=""][disabled] {
  display: none;
}
.select select:focus + svg {
  transform: rotate(0);
}
.sprites {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 767.7px) {
  .select,
  select {
    width: 100%;
  }
}
</style>
