<template>
  <div class="layout">
    <h2>Chain explorer</h2>
    <section class="section section--option">
      <Select v-model="selectedDagNet" :options="netDagOptions" storage-key="selectedDagNet"/>
      <span
        >network history contains <b>{{ dagListCountAnim }}</b></span
      >
      <Select v-model="selectedDagChain" :options="chainDagOptions" storage-key="selectedDagChain"/>
      <span>chain events</span>
    </section>
    <section class="section">
      <Dialog v-model:show="dialogDagVisible">
        <ul
          v-if="
            dagDetails.get(this.dialogDagValue) &&
            dagDetails.get(this.dialogDagValue).error
          "
          class="list-info"
        >
          <li>
            <span class="label">Error</span>
            <span class="value">{{
              dagDetails.get(this.dialogDagValue).error.msg
            }}</span>
          </li>
        </ul>
        <ul v-else-if="!dagDetails.get(this.dialogDagValue)" class="list-info">
          <li>
            <span class="value">Loading...</span>
          </li>
        </ul>
        <ul v-else class="list-info">
          <li>
            <span class="label">Event</span>
            <span class="value">{{ this.dialogDagValue }}</span>
          </li>
          <li class="list-bg">
            <ul class="list-info__list">
              <li
                v-if="
                  dagDetails.get(this.dialogDagValue).version ||
                  dagDetails.get(this.dialogDagValue).version == 0
                "
              >
                <span class="label">Version</span>
                <span class="value">{{
                  dagDetails.get(this.dialogDagValue).version
                }}</span>
              </li>
              <li
                v-if="
                  dagDetails.get(this.dialogDagValue).round_id ||
                  dagDetails.get(this.dialogDagValue).round_id == 0
                "
              >
                <span class="label">Round ID</span>
                <span class="value">{{
                  dagDetails.get(this.dialogDagValue).round_id
                }}</span>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).cell_id">
                <span class="label">Cell ID</span>
                <span class="value">{{
                  dagDetails.get(this.dialogDagValue).cell_id
                }}</span>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).chain_id">
                <span class="label">Chain ID</span>
                <span class="value">{{
                  dagDetails.get(this.dialogDagValue).chain_id
                }}</span>
              </li>
            </ul>
          </li>
          <li v-if="dagDetails.get(this.dialogDagValue).datum" class="list-bg">
            <ul class="list-info__list">
              <li v-if="dagDetails.get(this.dialogDagValue).datum.version">
                <span class="label">Datum version</span>
                <span class="value">{{
                  dagDetails.get(this.dialogDagValue).datum.version
                }}</span>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).datum.type">
                <span class="label">Datum type</span>
                <span class="value">{{
                  dagDetails.get(this.dialogDagValue).datum.type
                }}</span>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).datum.created">
                <span class="label">Datum timestamp</span>
                <span class="value">
                  {{ formatDate(dagDetails.get(this.dialogDagValue).datum.created) }}
                </span>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).datum.size">
                <span class="label">Datum size</span>
                <span class="value">{{
                  dagDetails.get(this.dialogDagValue).datum.size
                }}</span>
              </li>
              <li
                v-if="dagDetails.get(this.dialogDagValue).datum.data.tx"
                style="width: 100%"
              >
                <span class="label">Transaction hash</span>
                <div class="hash__item">
                  <router-link
                    :to="{
                      name: 'TransactionDetails',
                      params: {
                        hash: dagDetails.get(this.dialogDagValue).datum.data.tx
                          .hash,
                        net: this.selectedDagNet,
                        url: this.dagUrl,
                      },
                    }"
                    class="value value--action"
                  >
                    <span>{{
                      dagDetails.get(this.dialogDagValue).datum.data.tx.hash
                    }}</span>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'TransactionDetails',
                      params: {
                        hash: dagDetails.get(this.dialogDagValue).datum.data.tx
                          .hash,
                        net: this.selectedDagNet,
                        url: this.dagUrl,
                      },
                    }"
                    class="value value--action link--arrow"
                    target="_blank"
                  >
                    <img
                      src="@/assets/img/arrow_external-link.svg"
                      title="open in new tab"
                      alt="open in new tab"
                    />
                  </router-link>
                </div>
              </li>
              <li
                v-if="
                  dagDetails.get(this.dialogDagValue).datum.data.token_emission
                "
                class="list-bg"
              >
                <ul class="list-info__list">
                  <li>
                    <span class="label">Token emission address</span>
                    <span class="value">{{
                      dagDetails.get(this.dialogDagValue).datum.data
                        .token_emission.addr
                    }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission version</span>
                    <span class="value">{{
                      dagDetails.get(this.dialogDagValue).datum.data
                        .token_emission.version
                    }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission value</span>
                    <span class="value">{{
                      dagDetails.get(this.dialogDagValue).datum.data
                        .token_emission.value
                    }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission ticker</span>
                    <span class="value">{{
                      dagDetails.get(this.dialogDagValue).datum.data
                        .token_emission.ticker
                    }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission type</span>
                    <span class="value">{{
                      dagDetails.get(this.dialogDagValue).datum.data
                        .token_emission.type
                    }}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li
            v-else-if="dagDetails.get(this.dialogDagValue).datums"
            class="list-bg"
          >
            <ul
              v-for="item in dagDetails.get(this.dialogDagValue).datums"
              :key="item.data.tx.hash"
              class="list-info__list"
            >
              <li v-if="item.version">
                <span class="label">Datum version</span>
                <span class="value">{{ item.version }}</span>
              </li>
              <li v-if="item.type">
                <span class="label">Datum type</span>
                <span class="value">{{ item.type }}</span>
              </li>
              <li v-if="item.created">
                <span class="label">Datum timestamp</span>
                <span class="value">
                  {{ formatDate(item.created) }}
                </span>
              </li>
              <li v-if="item.size">
                <span class="label">Datum size</span>
                <span class="value">{{ item.size }}</span>
              </li>
              <li v-if="item.data.tx" class="w100">
                <span class="label">Transaction hash</span>
                <div class="hash__item">
                  <router-link
                    :to="{
                      name: 'TransactionDetails',
                      params: {
                        hash: item.data.tx.hash,
                        net: this.selectedDagNet,
                        url: this.dagUrl,
                      },
                    }"
                    class="value value--action"
                  >
                    <span>{{ item.data.tx.hash }}</span>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'TransactionDetails',
                      params: {
                        hash: item.data.tx.hash,
                        net: this.selectedDagNet,
                        url: this.dagUrl,
                      },
                    }"
                    class="value value--action link--arrow"
                    target="_blank"
                  >
                    <img
                      src="@/assets/img/arrow_external-link.svg"
                      class="transition-primary"
                      title="open in new tab"
                      alt="open in new tab"
                    />
                  </router-link>
                </div>
              </li>
              <li v-if="item.data.token_emission" class="list-bg">
                <ul class="list-info__list">
                  <li>
                    <span class="label">Token emission address</span>
                    <span class="value">{{
                      item.data.token_emission.addr
                    }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission version</span>
                    <span class="value">{{
                      item.data.token_emission.version
                    }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission value</span>
                    <span class="value">{{
                      item.data.token_emission.value
                    }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission ticker</span>
                    <span class="value">{{
                      item.data.token_emission.ticker
                    }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission type</span>
                    <span class="value">{{
                      item.data.token_emission.type
                    }}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li v-if="dagDetails.get(this.dialogDagValue).data_size">
            <span class="label">Data size</span>
            <span class="value">{{
              dagDetails.get(this.dialogDagValue).data_size
            }}</span>
          </li>
          <li v-if="dagDetails.get(this.dialogDagValue).signs" class="list-bg">
            <ul
              v-for="i in dagDetails.get(this.dialogDagValue).signs"
              :key="i.type"
              class="list-info__list"
            >
              <li>
                <span class="label">Signs type</span>
                <span class="value">{{ i.type }}</span>
              </li>
              <li>
                <span class="label">Signs size</span>
                <span class="value">{{ i.size }}</span>
              </li>
              <li v-if="i.pkey.size || i.pkey.size == 0">
                <span class="label">Signs pkey size</span>
                <span class="value">{{ i.pkey.size }}</span>
              </li>
              <li>
                <span class="label">Signs pkey type</span>
                <span class="value">{{ i.pkey.type }}</span>
              </li>
              <li>
                <span class="label">Signs pkey hash</span>
                <span class="value">{{ i.pkey_hash }}</span>
              </li>
              <li>
                <span class="label">Signs fingerprint</span>
                <span class="value">{{ i.addr }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </Dialog>
      <Dialog v-model:show="dialogTxVisible">
        <ul
          v-if="
            txDetails.get(this.dialogTxValue) &&
            txDetails.get(this.dialogTxValue).error
          "
          class="list-info"
        >
          <li>
            <span class="label">Error</span>
            <span class="value">{{
              txDetails.get(this.dialogTxValue).error
            }}</span>
          </li>
        </ul>
        <ul v-else-if="!txDetails.get(this.dialogTxValue)" class="list-info">
          <li>
            <span class="value">Loading...</span>
          </li>
        </ul>
        <ul v-else class="list-info">
          <li class="w100">
            <span class="label">Transaction hash</span>
            <div class="hash__item">
              <router-link
                :to="{
                  name: 'TransactionDetails',
                  params: {
                    hash: txDetails.get(this.dialogTxValue).hash,
                    net: this.selectedDagNet,
                    url: this.dagUrl,
                  },
                }"
                class="value value--action text-overflow"
              >
                <span>{{ txDetails.get(this.dialogTxValue).hash }}</span>
              </router-link>
              <router-link
                :to="{
                  name: 'TransactionDetails',
                  params: {
                    hash: txDetails.get(this.dialogTxValue).hash,
                    net: this.selectedDagNet,
                    url: this.dagUrl,
                  },
                }"
                class="value value--action link--arrow"
                target="_blank"
              >
                <img
                  src="@/assets/img/arrow_external-link.svg"
                  class="transition-primary"
                  title="open in new tab"
                  alt="open in new tab"
                />
              </router-link>
            </div>
          </li>
          <li
            v-if="txDetails.get(this.dialogTxValue).token.emissionHash"
            class="list-bg"
          >
            <ul class="list-info__list">
              <li>
                <span class="label">Emission chain id</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).token.emissionChainId
                }}</span>
              </li>
              <li>
                <span class="label">Emission hash</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).token.emissionHash
                }}</span>
              </li>
            </ul>
          </li>
          <li class="list-bg">
            <ul
              v-for="i in txDetails.get(this.dialogTxValue).in"
              :key="i.prev_hash"
              class="list-info__list"
            >
              <li v-if="i.prev_idx || i.prev_idx == 0">
                <span class="label">IN tx prev idx</span>
                <span class="value">{{ i.prev_idx }}</span>
              </li>
              <li v-if="i.prev_hash">
                <span class="label">IN tx prev hash</span>
                <span class="value">{{ i.prev_hash }}</span>
              </li>
            </ul>
          </li>
          <li
            v-if="txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock"
            class="list-bg"
          >
            <ul class="list-info__list">
              <li
                v-if="
                  txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                    .value
                "
              >
                <span class="label">OUT STAKE Value</span>
                <span class="value"
                  >{{
                    txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                      .value
                  }}
                  coins</span
                >
              </li>
              <li
                v-if="
                  txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                    .unlock
                "
              >
                <span class="label">Unlock date</span>
                <span class="value">
                  {{
                    txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                      .unlock.time
                  }}
                  {{
                    txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                      .unlock.day
                  }}
                  {{
                    monthNames[
                      txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                        .unlock.month - 1
                    ]
                  }}
                  {{
                    txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                      .unlock.year
                  }}
                </span>
              </li>
              <li
                v-if="
                  txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                    .reivestPercent
                "
              >
                <span class="label">Reinvestment percentage</span>
                <span class="value"
                  >{{
                    txDetails.get(this.dialogTxValue).out_cond_srv_stake_lock
                      .reivestPercent
                  }}
                  %
                </span>
              </li>
            </ul>
          </li>
          <li class="list-bg">
            <ul
              v-for="i in txDetails.get(this.dialogTxValue).out"
              :key="i.value"
              class="list-info__list"
            >
              <li v-if="i.value">
                <span class="label">OUT Value</span>
                <span class="value">{{ i.value }} coins</span>
              </li>
              <li v-if="i.addr">
                <span class="label">OUT Address</span>
                <span class="value">{{ i.addr }}</span>
              </li>
            </ul>
          </li>
          <li class="list-bg">
            <ul
              v-if="txDetails.get(this.dialogTxValue).sig[0]"
              class="list-info__list"
            >
              <li>
                <span class="label">SIG size</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).sig[0].size
                }}</span>
              </li>
              <li>
                <span class="label">SIGNATURE type</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).sig[0].sign.type
                }}</span>
              </li>
              <li>
                <span class="label">SIGNATURE size</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).sig[0].sign.size
                }}</span>
              </li>
              <li>
                <span class="label">SIGNATURE fingerprint</span>
                <span class="value">{{
                  txDetails.get(this.dialogTxValue).sig[0].sign.pkey_hash
                }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </Dialog>
      <div class="section__meta">
        <label class="search">
          <input
            v-model="inputDagValue"
            class="transition-primary"
            :disabled="dagSearchLoading"
            type="text"
            placeholder="0x28D8106BF04511CE25F51F08FBACD0F346C08A53EE13CC01F9355CED892B3D4D"
          />
          <button
            @click="searchDagItem()"
            class="search-button transition-primary"
            type="button"
            name="button"
          >
            <svg
              v-if="!dagSearchLoading"
              xmlns="http://www.w3.org/2000/svg"
              width="11.819"
              height="11.819"
              viewBox="0 0 11.819 11.819"
            >
              <path
                d="M13.535,12.528,10.91,9.909a4.991,4.991,0,1,0-1,1l2.619,2.625a.712.712,0,0,0,1.006,0h0a.712.712,0,0,0,0-1.006Zm-6.6-2.042a3.532,3.532,0,1,1,3.532-3.532,3.532,3.532,0,0,1-3.532,3.532Z"
                transform="translate(-1.924 -1.924)"
              />
            </svg>
            <svg
              v-else
              class="search-loader"
              version="1.1"
              id="L9"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve"
            >
              <path
                fill="#fff"
                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
              >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="1s"
                  from="0 50 50"
                  to="360 50 50"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </button>
        </label>
        <Select v-model="selectedSortDagItem" :options="sortDagOptions" storage-key="selectedSortDagItem"/>
      </div>
      <table class="table table--event">
        <tbody v-if="!dagLoading" class="table__list">
          <tr v-for="item in dagList" :key="item.hash" class="table__item">
            <details>
              <summary @click="getDagInfo(item.hash)" class="item__header">
                <td data-label="Timestamp" class="timestamp">
                  {{ getFormattedTime(item.date) }}
                </td>
                <td data-label="Hash" class="hash">
                  <div class="hash__wrap">
                    <span class="hash__pseudo">0x0</span>
                    <span class="hash__primary transition-primary">{{
                      item.hash
                    }}</span>
                  </div>
                </td>
              </summary>
              <ul
                v-if="
                  dagDetails.get(item.hash) && dagDetails.get(item.hash).error
                "
                class="item__body list-info"
              >
                <li>
                  <span class="label">Error</span>
                  <span class="value">{{
                    dagDetails.get(item.hash).error.msg
                  }}</span>
                </li>
              </ul>
              <ul
                v-else-if="!dagDetails.get(item.hash)"
                :ref="'hash-ref-' + item.hash"
                class="item__body list-info"
              >
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">Version</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">Round ID</span>
                      <span class="value anim anim-delay-1"></span>
                    </li>
                    <li>
                      <span class="label">Cell ID</span>
                      <span class="value anim anim-delay-2"></span>
                    </li>
                    <li>
                      <span class="label">Chain ID</span>
                      <span class="value anim anim-delay-3"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">Datum version</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">Datum type</span>
                      <span class="value anim anim-delay-1"></span>
                    </li>
                    <li>
                      <span class="label">Datum timestamp</span>
                      <span class="value anim anim-delay-2"></span>
                    </li>
                    <li>
                      <span class="label">Datum size</span>
                      <span class="value anim anim-delay-3"></span>
                    </li>
                    <li class="w100">
                      <span class="label">Transaction hash</span>
                      <div class="hash__item">
                        <span class="value value--action anim"></span>
                      </div>
                    </li>
                    <li class="list-bg">
                      <ul class="list-info__list">
                        <li class="w100">
                          <span class="label">Token emission address</span>
                          <span class="value anim"></span>
                        </li>
                        <li>
                          <span class="label">Token emission version</span>
                          <span class="value anim"></span>
                        </li>
                        <li>
                          <span class="label">Token emission value</span>
                          <span class="value anim anim-delay-1"></span>
                        </li>
                        <li>
                          <span class="label">Token emission ticker</span>
                          <span class="value anim anim-delay-2"></span>
                        </li>
                        <li>
                          <span class="label">Token emission type</span>
                          <span class="value anim anim-delay-3"></span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">Signs type</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">Signs size</span>
                      <span class="value anim anim-delay-1"></span>
                    </li>
                    <li>
                      <span class="label">Signs pkey size</span>
                      <span class="value anim anim-delay-2"></span>
                    </li>
                    <li>
                      <span class="label">Signs pkey type</span>
                      <span class="value anim anim-delay-3"></span>
                    </li>
                    <li class="w100">
                      <span class="label">Signs pkey hash</span>
                      <span class="value anim"></span>
                    </li>
                    <li class="w100">
                      <span class="label">Signs fingerprint</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul v-else class="item__body list-info">
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li
                      v-if="
                        dagDetails.get(item.hash).version ||
                        dagDetails.get(item.hash).version == 0
                      "
                    >
                      <span class="label">Version</span>
                      <span class="value">{{
                        dagDetails.get(item.hash).version
                      }}</span>
                    </li>
                    <li
                      v-if="
                        dagDetails.get(item.hash).round_id ||
                        dagDetails.get(item.hash).round_id == 0
                      "
                    >
                      <span class="label">Round ID</span>
                      <span class="value">{{
                        dagDetails.get(item.hash).round_id
                      }}</span>
                    </li>
                    <li v-if="dagDetails.get(item.hash).cell_id">
                      <span class="label">Cell ID</span>
                      <span class="value">{{
                        dagDetails.get(item.hash).cell_id
                      }}</span>
                    </li>
                    <li v-if="dagDetails.get(item.hash).chain_id">
                      <span class="label">Chain ID</span>
                      <span class="value">{{
                        dagDetails.get(item.hash).chain_id
                      }}</span>
                    </li>
                  </ul>
                </li>
                <li v-if="dagDetails.get(item.hash).datum" class="list-bg">
                  <ul class="list-info__list">
                    <li v-if="dagDetails.get(item.hash).datum.version">
                      <span class="label">Datum version</span>
                      <span class="value">{{
                        dagDetails.get(item.hash).datum.version
                      }}</span>
                    </li>
                    <li v-if="dagDetails.get(item.hash).datum.type">
                      <span class="label">Datum type</span>
                      <span class="value">{{
                        dagDetails.get(item.hash).datum.type
                      }}</span>
                    </li>
                    <li v-if="dagDetails.get(item.hash).datum.created">
                      <span class="label">Datum timestamp</span>
                      <span class="value">
                        {{ formatDate(dagDetails.get(item.hash).datum.created) }}
                      </span>
                    </li>
                    <li v-if="dagDetails.get(item.hash).datum.size">
                      <span class="label">Datum size</span>
                      <span class="value">{{
                        dagDetails.get(item.hash).datum.size
                      }}</span>
                    </li>
                    <li
                      v-if="dagDetails.get(item.hash).datum.data.tx"
                      class="w100"
                    >
                      <span class="label">Transaction hash</span>
                      <div class="hash__item">
                        <router-link
                          :to="{
                            name: 'TransactionDetails',
                            params: {
                              hash: dagDetails.get(item.hash).datum.data.tx
                                .hash,
                              net: this.selectedDagNet,
                              url: this.dagUrl,
                            },
                          }"
                          class="value value--action"
                        >
                          <span class="transition-primary">{{
                            dagDetails.get(item.hash).datum.data.tx.hash
                          }}</span>
                        </router-link>
                        <router-link
                          :to="{
                            name: 'TransactionDetails',
                            params: {
                              hash: dagDetails.get(item.hash).datum.data.tx
                                .hash,
                              net: this.selectedDagNet,
                              url: this.dagUrl,
                            },
                          }"
                          class="value value--action link--arrow"
                          target="_blank"
                        >
                          <img
                            src="@/assets/img/arrow_external-link.svg"
                            class="transition-primary"
                            title="open in new tab"
                            alt="open in new tab"
                          />
                        </router-link>
                      </div>
                    </li>
                    <li
                      v-if="dagDetails.get(item.hash).datum.data.token_emission"
                      class="list-bg"
                    >
                      <ul class="list-info__list">
                        <li>
                          <span class="label">Token emission address</span>
                          <span class="value">{{
                            dagDetails.get(item.hash).datum.data.token_emission
                              .addr
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Token emission version</span>
                          <span class="value">{{
                            dagDetails.get(item.hash).datum.data.token_emission
                              .version
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Token emission value</span>
                          <span class="value">{{
                            dagDetails.get(item.hash).datum.data.token_emission
                              .value
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Token emission ticker</span>
                          <span class="value">{{
                            dagDetails.get(item.hash).datum.data.token_emission
                              .ticker
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Token emission type</span>
                          <span class="value">{{
                            dagDetails.get(item.hash).datum.data.token_emission
                              .type
                          }}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li
                  v-else-if="
                    dagDetails.get(item.hash).datums &&
                    dagDetails.get(item.hash).datums.length > 1
                  "
                  class="list-bg"
                >
                  <ul
                    v-for="item in dagDetails.get(item.hash).datums.slice(0, 1)"
                    :key="item.data.tx.hash"
                    class="list-info__list"
                  >
                    <li v-if="item.version">
                      <span class="label">Datum version</span>
                      <span class="value">{{ item.version }}</span>
                    </li>
                    <li v-if="item.type">
                      <span class="label">Datum type</span>
                      <span class="value">{{ item.type }}</span>
                    </li>
                    <li v-if="item.created">
                      <span class="label">Datum timestamp</span>
                      <span class="value">
                        {{ formatDate(item.created) }}
                      </span>
                    </li>
                    <li v-if="item.size">
                      <span class="label">Datum size</span>
                      <span class="value">{{ item.size }}</span>
                    </li>
                    <li v-if="item.data.tx" class="w100">
                      <span class="label">Transaction hash</span>
                      <div class="hash__item">
                        <router-link
                          :to="{
                            name: 'TransactionDetails',
                            params: {
                              hash: item.data.tx.hash,
                              net: this.selectedDagNet,
                              url: this.dagUrl,
                            },
                          }"
                          class="value value--action"
                        >
                          <span class="transition-primary">{{
                            item.data.tx.hash
                          }}</span>
                        </router-link>
                        <router-link
                          :to="{
                            name: 'TransactionDetails',
                            params: {
                              hash: item.data.tx.hash,
                              net: this.selectedDagNet,
                              url: this.dagUrl,
                            },
                          }"
                          class="value value--action link--arrow"
                          target="_blank"
                        >
                          <img
                            src="@/assets/img/arrow_external-link.svg"
                            class="transition-primary"
                            title="open in new tab"
                            alt="open in new tab"
                          />
                        </router-link>
                      </div>
                    </li>
                    <li v-if="item.data.token_emission" class="list-bg">
                      <ul class="list-info__list">
                        <li>
                          <span class="label">Token emission address</span>
                          <span class="value">{{
                            item.data.token_emission.addr
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Token emission version</span>
                          <span class="value">{{
                            item.data.token_emission.version
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Token emission value</span>
                          <span class="value">{{
                            item.data.token_emission.value
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Token emission ticker</span>
                          <span class="value">{{
                            item.data.token_emission.ticker
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Token emission type</span>
                          <span class="value">{{
                            item.data.token_emission.type
                          }}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <details class="slice-more">
                    <summary class="slice-more__title transition-primary">
                      <span class="transition-primary"
                        >+{{
                          dagDetails.get(item.hash).datums.length - 1
                        }}</span
                      >
                    </summary>
                    <ul
                      v-for="item in dagDetails.get(item.hash).datums.slice(1)"
                      :key="item.data.tx.hash"
                      class="list-info__list"
                    >
                      <li v-if="item.version">
                        <span class="label">Datum version</span>
                        <span class="value">{{ item.version }}</span>
                      </li>
                      <li v-if="item.type">
                        <span class="label">Datum type</span>
                        <span class="value">{{ item.type }}</span>
                      </li>
                      <li v-if="item.created">
                        <span class="label">Datum timestamp</span>
                        <span class="value">
                          {{ formatDate(item.created) }}
                        </span>
                      </li>
                      <li v-if="item.size">
                        <span class="label">Datum size</span>
                        <span class="value">{{ item.size }}</span>
                      </li>
                      <li v-if="item.data.tx" class="w100">
                        <span class="label">Transaction hash</span>
                        <div class="hash__item">
                          <router-link
                            :to="{
                              name: 'TransactionDetails',
                              params: {
                                hash: item.data.tx.hash,
                                net: this.selectedDagNet,
                                url: this.dagUrl,
                              },
                            }"
                            class="value value--action"
                          >
                            <span class="transition-primary">{{
                              item.data.tx.hash
                            }}</span>
                          </router-link>
                          <router-link
                            :to="{
                              name: 'TransactionDetails',
                              params: {
                                hash: item.data.tx.hash,
                                net: this.selectedDagNet,
                                url: this.dagUrl,
                              },
                            }"
                            class="value value--action link--arrow"
                            target="_blank"
                          >
                            <img
                              src="@/assets/img/arrow_external-link.svg"
                              class="transition-primary"
                              title="open in new tab"
                              alt="open in new tab"
                            />
                          </router-link>
                        </div>
                      </li>
                      <li v-if="item.data.token_emission" class="list-bg">
                        <ul class="list-info__list">
                          <li>
                            <span class="label">Token emission address</span>
                            <span class="value">{{
                              item.data.token_emission.addr
                            }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission version</span>
                            <span class="value">{{
                              item.data.token_emission.version
                            }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission value</span>
                            <span class="value">{{
                              item.data.token_emission.value
                            }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission ticker</span>
                            <span class="value">{{
                              item.data.token_emission.ticker
                            }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission type</span>
                            <span class="value">{{
                              item.data.token_emission.type
                            }}</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </details>
                </li>
                <li
                  v-else-if="
                    dagDetails.get(item.hash).datums &&
                    dagDetails.get(item.hash).datums.length < 2 &&
                    dagDetails.get(item.hash).datums.length > 0
                  "
                  class="list-bg"
                >
                  <ul
                    v-for="item in dagDetails.get(item.hash).datums"
                    :key="item.data.hash"
                    class="list-info__list"
                  >
                    <li v-if="item.version">
                      <span class="label">Datum version</span>
                      <span class="value">{{ item.version }}</span>
                    </li>
                    <li v-if="item.type">
                      <span class="label">Datum type</span>
                      <span class="value">{{ item.type }}</span>
                    </li>
                    <li v-if="item.created">
                      <span class="label">Datum timestamp</span>
                      <span class="value">
                        {{ formatDate(item.created) }}
                      </span>
                    </li>
                    <li v-if="item.size">
                      <span class="label">Datum size</span>
                      <span class="value">{{ item.size }}</span>
                    </li>
                    <li v-if="item.data.tx" class="w100">
                      <span class="label">Transaction hash</span>
                      <div class="hash__item">
                        <router-link
                          :to="{
                            name: 'TransactionDetails',
                            params: {
                              hash: item.data.tx.hash,
                              net: this.selectedDagNet,
                              url: this.dagUrl,
                            },
                          }"
                          class="value value--action"
                        >
                          <span class="transition-primary">{{
                            item.data.tx.hash
                          }}</span>
                        </router-link>
                        <router-link
                          :to="{
                            name: 'TransactionDetails',
                            params: {
                              hash: item.data.tx.hash,
                              net: this.selectedDagNet,
                              url: this.dagUrl,
                            },
                          }"
                          class="value value--action link--arrow"
                          target="_blank"
                        >
                          <img
                            src="@/assets/img/arrow_external-link.svg"
                            class="transition-primary"
                            title="open in new tab"
                            alt="open in new tab"
                          />
                        </router-link>
                      </div>
                    </li>
                    <li v-if="item.data.token_emission" class="list-bg">
                      <ul class="list-info__list">
                        <li>
                          <span class="label">Token emission address</span>
                          <span class="value">{{
                            item.data.token_emission.addr
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Token emission version</span>
                          <span class="value">{{
                            item.data.token_emission.version
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Token emission value</span>
                          <span class="value">{{
                            item.data.token_emission.value
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Token emission ticker</span>
                          <span class="value">{{
                            item.data.token_emission.ticker
                          }}</span>
                        </li>
                        <li>
                          <span class="label">Token emission type</span>
                          <span class="value">{{
                            item.data.token_emission.type
                          }}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li v-if="dagDetails.get(item.hash).data_size">
                  <span class="label">Data size</span>
                  <span class="value">{{
                    dagDetails.get(item.hash).data_size
                  }}</span>
                </li>
                <li
                  v-if="
                    dagDetails.get(item.hash).signs &&
                    dagDetails.get(item.hash).signs.length > 1
                  "
                  class="list-bg"
                >
                  <ul
                    v-for="i in dagDetails.get(item.hash).signs.slice(0, 1)"
                    :key="i.type"
                    class="list-info__list"
                  >
                    <li>
                      <span class="label">Signs type</span>
                      <span class="value">{{ i.type }}</span>
                    </li>
                    <li>
                      <span class="label">Signs size</span>
                      <span class="value">{{ i.size }}</span>
                    </li>
                    <li v-if="i.pkey.size || i.pkey.size == 0">
                      <span class="label">Signs pkey size</span>
                      <span class="value">{{ i.pkey.size }}</span>
                    </li>
                    <li>
                      <span class="label">Signs pkey type</span>
                      <span class="value">{{ i.pkey.type }}</span>
                    </li>
                    <li class="w100">
                      <span class="label">Signs pkey hash</span>
                      <span class="value">{{ i.pkey_hash }}</span>
                    </li>
                    <li class="w100">
                      <span class="label">Signs fingerprint</span>
                      <span class="value">{{ i.addr }}</span>
                    </li>
                  </ul>
                  <details class="slice-more">
                    <summary class="slice-more__title transition-primary">
                      <span class="transition-primary"
                        >+{{ dagDetails.get(item.hash).signs.length - 1 }}</span
                      >
                    </summary>
                    <ul
                      v-for="i in dagDetails.get(item.hash).signs.slice(1)"
                      :key="i.type"
                      class="list-info__list"
                    >
                      <li>
                        <span class="label">Signs type</span>
                        <span class="value">{{ i.type }}</span>
                      </li>
                      <li>
                        <span class="label">Signs size</span>
                        <span class="value">{{ i.size }}</span>
                      </li>
                      <li v-if="i.pkey.size || i.pkey.size == 0">
                        <span class="label">Signs pkey size</span>
                        <span class="value">{{ i.pkey.size }}</span>
                      </li>
                      <li>
                        <span class="label">Signs pkey type</span>
                        <span class="value">{{ i.pkey.type }}</span>
                      </li>
                      <li class="w100">
                        <span class="label">Signs pkey hash</span>
                        <span class="value">{{ i.pkey_hash }}</span>
                      </li>
                      <li class="w100">
                        <span class="label">Signs fingerprint</span>
                        <span class="value">{{ i.addr }}</span>
                      </li>
                    </ul>
                  </details>
                </li>
                <li
                  v-else-if="
                    dagDetails.get(item.hash).signs &&
                    dagDetails.get(item.hash).signs.length < 2 &&
                    dagDetails.get(item.hash).signs.length > 0
                  "
                  class="list-bg"
                >
                  <ul
                    v-for="i in dagDetails.get(item.hash).signs"
                    :key="i.type"
                    class="list-info__list"
                  >
                    <li>
                      <span class="label">Signs type</span>
                      <span class="value">{{ i.type }}</span>
                    </li>
                    <li>
                      <span class="label">Signs size</span>
                      <span class="value">{{ i.size }}</span>
                    </li>
                    <li v-if="i.pkey.size || i.pkey.size == 0">
                      <span class="label">Signs pkey size</span>
                      <span class="value">{{ i.pkey.size }}</span>
                    </li>
                    <li>
                      <span class="label">Signs pkey type</span>
                      <span class="value">{{ i.pkey.type }}</span>
                    </li>
                    <li class="w100">
                      <span class="label">Signs pkey hash</span>
                      <span class="value">{{ i.pkey_hash }}</span>
                    </li>
                    <li class="w100">
                      <span class="label">Signs fingerprint</span>
                      <span class="value">{{ i.addr }}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </details>
          </tr>
        </tbody>
        <tbody v-else class="placeholder">
          <tr v-for="item in size" :key="item">
            <td data-label="Timestamp" class="timestamp">
              <span class="anim"></span>
            </td>
            <td data-label="Hash" class="hash">
              <span class="anim anim-delay-1"></span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button
          @click="prevDagPage"
          :disabled="dagPage.cur === 1"
          class="pagination__prev"
        ></button>
        <span
          >Showing {{ dagPage.startItem }} to
          <span v-if="dagPage.endItem >= dagListCount">{{ dagListCount }}</span
          ><span v-else>{{ dagPage.endItem }}</span> of
          {{ dagListCount }} entries</span
        >
        <button
          @click="nextDagPage"
          :disabled="dagPage.cur >= dagPage.total"
          class="pagination__next"
        ></button>
      </div>
    </section>
  </div>
  <Hint v-model:show="hintVisible">
    <p>{{ hintText }}</p>
  </Hint>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import axios from "axios";
import netsAvalible from "@/store/nets.js";

import { globalMethodsMixin } from "@/mixins/globalMethodsMixin";

export default {
  mixins: [globalMethodsMixin],
  data() {
    return {
      hintVisible: false,
      hintText: "Error",
      // dag
      dagSearchLoading: false,
      dagLoading: false,

      dagList: [],

      dagListCount: 0,

      dagListCountAnim: 0,

      dialogDagVisible: false,
      dialogTxVisible: false,

      dialogDagValue: "",
      dialogTxValue: "",

      inputDagValue: "",

      selectedDagNet: "Backbone",
      netDagOptions: [],

      selectedDagChain: "",
      chainDagOptions: [],

      dagDetails: new Map(),
      txDetails: new Map(),

      dagPage: {
        total: 0,
        cur: 1,
        pageLimit: 10,
        startItem: 1,
        endItem: 1,
        itemCount: 100,
      },

      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      selectedSortDagItem: "new",
      sortDagOptions: [
        { value: "new", name: "first new" },
        { value: "old", name: "old ones first" },
      ],

      dagUrl: "https://1.api.explorer.cellframe.net/expl",
    };
  },

  props: {
    size: {
      type: Number,
      required: false,
      default: 100,
    },
  },

  created() {
    if (localStorage.getItem(this.storageKey)) {
      this.selectedDagChain = localStorage.getItem(this.storageKey);
    } else {
      this.selectedDagChain = "main";
    }

      if (this.$route.query.selectedDagNet) {
        this.selectedDagNet = this.$route.query.selectedDagNet;
      }
    },

  methods: {
    dagAnimateCount(animationDuration) {
      const frameDuration = 1000 / 60;
      const totalFrames = Math.round(animationDuration / frameDuration);
      const easeOutQuad = (t) => 1 - Math.pow(1 - t, 5);

      let frame = 0;
      const countTo = this.dagListCount;

      const counter = setInterval(() => {
        frame++;

        const progress = easeOutQuad(frame / totalFrames);

        const currentCount = Math.round(countTo * progress);

        if (this.dagListCountAnim !== currentCount) {
          this.dagListCountAnim = currentCount;
        }

        if (frame === totalFrames) {
          clearInterval(counter);
        }
      }, frameDuration);
    },

    async dagItemsCount() {
      try {
        this.dagLoading = true;

        let method = "dagListCount";
        if (this.selectedDagChain == "main") method = "blockListCount";

        const response = await axios.get(this.dagUrl, {
          params: {
            method: method,
            net: this.selectedDagNet,
            chain: this.selectedDagChain,
          },
        });

        this.dagListCount = response.data.count;

        if (response.data.count <= this.size) {
          this.dagPage.itemCount = response.data.count;
        } else {
          this.dagPage.itemCount = this.size;
        }

        this.dagAnimateCount(2000);

        console.log(response.data);

        this.dagItemsLimited();

        this.dagPageCount();
        this.dagPaginatedData();
      } catch (error) {
        this.dagLoading = true;
        console.log(error);
        // let step = 1;
        // // let count = response.data.count / this.dagListCount;
        // let count = 9872;
        // if (count > 1000) {
        //   step = 100
        // } else if (count < 1000 && count > 10) {
        //   step = 10
        // } else {
        //   step = 1
        // }
        // let t = Math.round(1000/(count / step));
        // console.log(step, count, t)
        // let interval = setInterval(() => {
        //   this.dagListCount = this.dagListCount + step;
        //   if (this.dagListCount == count || this.dagListCount > count) {
        //     this.dagListCount = count;
        //     clearInterval(interval);
        //   }
        //   console.log(this.dagListCount)
        // }, t)
      }
    },

    async dagItemsLimited() {
      try {
        let method = "dagListLimited";
        if (this.selectedDagChain == "main") method = "blockListLimited";
        let params = {
          method: method,
          net: this.selectedDagNet,
          chain: this.selectedDagChain,
          count: this.dagPage.itemCount,
          page: this.dagPage.cur,
        };
        if (this.selectedSortDagItem == "old") params.reverse = "old";

        setTimeout(async () => {
          const response = await axios.get(this.dagUrl, {
            params: params,
          });

          if (this.selectedDagChain == "main") {
            console.log(response.data.blocks);
            this.dagList = response.data.blocks;
          } else {
            console.log(response.data.events);
            this.dagList = response.data.events;
          }
          this.dagLoading = false;
        }, 1000);
      } catch (error) {
        this.dagLoading = true;
        console.log(error.message);
      }
    },

    async getDagInfo(hash) {
      if (!this.dagDetails.has(hash)) {
        try {
          let method = "dag";
          if (this.selectedDagChain == "main") method = "block";

          setTimeout(async () => {
            const response = await axios.get(this.dagUrl, {
              params: {
                method: method,
                net: this.selectedDagNet,
                chain: this.selectedDagChain,
                hash: hash,
              },
            });
            // let needle_index = this.$refs['hash-ref-'+hash];

            console.log(response.data);
            this.dagDetails.set(hash, response.data);
          }, 1000);
        } catch (error) {
          console.log(error.message);
        }
      }
    },
    

    async searchDagItem() {
      if (this.dagSearchLoading) {
        return
      }
      if (this.dagDetails.has(this.inputDagValue)) {
        this.dialogDagValue = this.inputDagValue;
        this.showDagDialog();
      } else if (this.inputDagValue.length > 60) {
        this.dagSearchLoading = true;
        try {
          let method = "dag";
          if (this.selectedDagChain == "main") method = "block";

          const response = await axios.get(this.dagUrl, {
            params: {
              method: method,
              net: this.selectedDagNet,
              chain: this.selectedDagChain,
              hash: this.inputDagValue,
            },
          });

          console.log(response.data);
          if (response.data.error) {
            throw new Error(response.data.error.msg);
          }
          if (response.data) {
            this.dagDetails.set(this.inputDagValue, response.data);
            this.dialogDagValue = this.inputDagValue;
            this.showDagDialog();
          }
          this.dagSearchLoading = false;
        } catch (error) {
          this.dagSearchLoading = false;
          console.log(error.message);
          this.hintText = error.message;
          this.showHint();
        }
      } else if (this.inputDagValue.length < 60) {
        this.hintText = "Incorrect chain value";
        this.showHint();
      }
    },
    

    async searchTxItem(hash, net, url) {
      try {
        const response = await axios.get(url, {
          params: {
            method: "tx",
            net: net,
            hash: hash,
          },
        });
console.log('Event', response.data);
        console.log(response.data);
        if (response.data) {
          this.txDetails.set(hash, response.data);
          this.dialogTxValue = hash;
          this.showTxDialog();
        }
      } catch (error) {
        console.log(error.message);
      }
    },

    showDagDialog() {
      this.dialogDagVisible = true;
    },
    showTxDialog() {
      this.dialogTxVisible = true;
    },

    nextDagPage() {
      this.dagPage.cur++;
      if (this.selectedSortDagItem == "new") {
        this.dagItemsCount();
        this.dagPaginatedData();
      } else if (this.selectedSortDagItem == "old") {
        this.dagItemsCount();
        this.dagPaginatedData();
      }
    },
    prevDagPage() {
      this.dagPage.cur--;
      if (this.selectedSortDagItem == "new") {
        this.dagItemsCount();
        this.dagPaginatedData();
      } else if (this.selectedSortDagItem == "old") {
        this.dagItemsCount();
        this.dagPaginatedData();
      }
    },
    dagPageCount() {
      let l = this.dagListCount,
        s = this.dagPage.itemCount;
      this.dagPage.total = Math.ceil(l / s);
      return this.dagPage.total;
    },
    dagPaginatedData() {
      const start = Number((this.dagPage.cur - 1) * this.dagPage.itemCount),
        end = Number(start + this.dagPage.itemCount);
      this.dagPage.startItem = start + 1;
      this.dagPage.endItem = end;
    },
    showHint() {
      // this.hintMsg = msg;
      this.hintVisible = true;

      setTimeout(() => {
        this.hintVisible = false;
      }, 2000);
    },
    formatDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const day = date.getDate();
      const month = date.toLocaleString('en-US', { month: 'long' });
      const year = date.getFullYear();

      const time = date.toLocaleString('ru-RU', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      });

      return `${time} ${day} ${month} ${year}`;
    },
  },

  mounted() {
    for (let entry of this.nets) {
      this.netDagOptions.push(entry[1].net);
    }
    this.chainDagOptions = this.nets.get(this.selectedDagNet).chains;
    this.dagItemsCount();
  },

  setup() {
    const nets = ref(netsAvalible);
    return { nets };
  },

  watch: {
    selectedDagNet() {
      this.dagPage.cur = 1;
      this.selectedSortDagItem = "new";
      this.chainDagOptions = this.nets.get(this.selectedDagNet).chains;
      this.dagUrl = this.nets.get(this.selectedDagNet).url;
      this.dagItemsCount();

      this.$router.push({
        name: 'Event',
        query: {
          selectedDagNet: this.selectedDagNet,
          selectedDagChain: this.selectedDagChain
        }
      });
    },
    selectedDagChain() {
      this.dagPage.cur = 1;
      this.selectedSortDagItem = "new";
      this.dagItemsCount();

      this.$router.push({
        name: 'Event',
        query: {
          selectedDagNet: this.selectedDagNet,
          selectedDagChain: this.selectedDagChain
        }
      });
    },
    // inputDagValue() {
    //   if (this.dagDetails.has(this.inputDagValue)) {
    //     this.dialogDagValue = this.inputDagValue
    //     this.showDagDialog()
    //   } else if (this.inputDagValue.length > 60) {
    //     this.searchDagItem(this.inputDagValue)
    //   }
    // },
    selectedSortDagItem() {
      this.dagPage.cur = 1;
      if (this.selectedSortDagItem == "new") {
        this.dagItemsCount();
      } else if (this.selectedSortDagItem == "old") {
        this.dagItemsCount();
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/layout.css";
@import "../assets/css/pagination.css";

.layout {
  margin: 48px 0 0;
  padding: 0 16px 48px;
}

.table--event .hash__wrap span {
  width: 100%;
}
.hash__wrap .hash__primary,
.hash__wrap .hash__pseudo {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.hash__wrap .hash__pseudo {
  color: var(--color-bg-primary);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 767.7px) {
  .hash__wrap .hash__pseudo {
    position: relative;
  }
  .hash__wrap .hash__primary {
    position: absolute;
  }
}

@media screen and (min-width: 768px) {
  .hash__wrap .hash__primary,
  .hash__wrap .hash__pseudo {
    position: absolute;
  }
  .timestamp {
    min-width: 210px; 
  }
}
</style>
