import Dialog from "@/components/UI/Dialog";
import InputSearch from "@/components/UI/InputSearch";
import Select from "@/components/UI/Select";
import Table from "@/components/UI/Table";
import Hint from "@/components/UI/hint";

export default [
    Dialog,
    InputSearch,
    Select,
    Table,
    Hint
]
