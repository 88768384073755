import {
  createRouter,
  createWebHistory,
  // createWebHashHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import Event from '../views/Event.vue'
import Transaction from '../views/Transaction.vue'
import TransactionDetails from '../views/TransactionDetails.vue'

// const express = require('express')
// const cors = require('cors')

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
      },
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import ( /* webpackChunkName: "about" */ '../views/About.vue')
    // },
    {
        path: '/event',
        name: 'Event',
        component: Event
    },
    {
        path: '/transaction',
        name: 'Transaction',
        component: Transaction
    },
    {
        path: '/transaction/:net/:hash',
        name: 'TransactionDetails',
        props: true,
        component: TransactionDetails,
    }
]

// const app = express()

// app.use(cors())

const router = createRouter({
    history: createWebHistory(),
    // mode: 'history',
    routes,
})

export default router
